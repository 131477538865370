<template>
	<div style="width: 100%;text-align: center;">
		<div id="moduleStoreroomKj1ix29js2" @click="moduleStoreroomKj1ix29js2" style="margin-bottom: 100px;"></div>
	</div>
	<a-modal v-model:visible="visible" wrapClassName="moudle-default-j2x" :destroyOnClose="true" :centered="true" :keyboard="false" :maskClosable="false"
		title="模块编辑" okText="确认" cancelText="取消" width="750px" @ok="ok" @cancel="cancel" :footer="null">
		<div style="padding: 24px;"><ModuleLibrary ref="childModule" :info="moduleInfo"></ModuleLibrary></div>
		<div class="moudle-footer-box-bh1">
			<div class="moudle-footer-box-bh1__l">
				<a-button @click="saveMoudle" v-if="moduleInfo.extraData&&moduleInfo.extraData.tpl_mode==1&&moduleInfo.extraData.info.moudle_id>0" type="dashed">{{moduleInfo.extraData.info.moudle_id>0?'修改模板':'保存模板'}}</a-button>
			</div>
			<div class="moudle-footer-box-bh1__r">
				<a-button style="margin-right: 8px;" @click="cancel">取消</a-button>
				<a-button type="primary" @click="ok">确认</a-button>
			</div>
		</div>
	</a-modal>
</template>

<script>
import ModuleLibrary from './components/moduleLibrary.vue';
import { message } from 'ant-design-vue';
export default {
	name: 'Module',
	components: {
		ModuleLibrary
	},
	data() {
		return {
			mouduleRedirectOk:null,
			getHtmlData:'',
			testHtml: '',
			visible: false,
			moduleInfo: {},
			testLists:[],
		}
	},
	mounted() {
	},
	methods: {
		moduleStoreroomKj1ix29js2() {//打开弹窗
			this.moduleInfo = JSON.parse(JSON.stringify(moduleStoreroomKj1ix29js2Data));
			this.visible = true;
		},
		//保存模板
		saveMoudle(){
			this.$refs.childModule.getData().then((result)=>{
				this.$apiRequest.post('/api/storeroom/saveMoudle',{
					moudle_id:this.moduleInfo.extraData.info.moudle_id,
					vip:2,
					content:JSON.stringify(result.data),
				}).then(res=>{
					message.success(res.data.message)
				}).catch(err => {
				});
			})
		},
		ok() {//添加-修改 确认
			this.$refs.childModule.getData().then((result)=>{
				if(typeof mouduleRedirectConfirm=='function'){
					mouduleRedirectConfirm(1,result,this.moduleInfo.extraData)
				}else{
					result.html = this.getHtml(result.data.tpl_id,result.data)
					if(this.moduleInfo.extraData.index>-1){
						//修改
						this.testLists[this.moduleInfo.extraData.index]=JSON.parse(JSON.stringify(result))
					}else{
						//新增
						this.testLists.unshift(JSON.parse(JSON.stringify(result)))
					}
				}
				setTimeout(()=>{
					this.visible = false;
				},100)
			}).catch(()=>{
				
			})
		},
		cancel() {//取消
			if(typeof mouduleRedirectConfirm=='function'){
				mouduleRedirectConfirm(2,{data:null,html:""},this.moduleInfo.extraData)
			}
			this.visible = false;
		},
		getHtml(tpl_id,data){
			let that = this
			function mediaFileAuthKeyJ1u2sx(type,url,frame){
				if(type=='image'){
					return that.$baseFunc.getAliOssAuthKey(url)
				}else if(type=='video'){
					return that.$baseFunc.getQiniusign(url,frame)
				}else if(type=='time'){
					return that.$baseFunc.converNumberToTime(url)
				}
			}
			let _child_html=``;
			switch (parseInt(tpl_id)) {
				case 301:
					_child_html=``;
					data.lists.forEach(item=>{
						_child_html += `<div class="hch_video_box" style="border-width:0;">
						  <div class="hch_video_cover_box" style="border-radius: ${data.media_radius}px;padding-bottom:${(data.cover_height/139.5)*56.25}%">
						    <img src="${mediaFileAuthKeyJ1u2sx("image",item.cover.url)}">
						    <div class="hch_video_duration">${mediaFileAuthKeyJ1u2sx("time",item.video.duration) }</div>
						  </div>
						  <p class="hch_video_title" style=" color: ${item.title_color }">${ item.title }</p>
						  <p class="hch_video_author" style=" color: ${item.author_color }">${ item.author }</p>
						</div>`;
					})
					return `<div class="hch_video" style="border-radius: ${data.radius}px; box-shadow: ${data.shadow ? '0 2px 10px 0 rgba(0,0,0,.15)' : 'none'}; padding: ${data.shadow ? '14px 15px' : '0px'};width:100%;" ref="video">${_child_html}</div>`;
					break;
				case 302:
					_child_html=``;
					data.lists.forEach(item=>{
						_child_html+=`<div class="hch_flow_box" style="border-width:0;">
									<div class="hch_prover" style="flex-direction: ${item.align == 'left' ? 'row' : 'row-reverse'}">
										<div class="hch_flow_left" style=" margin-left: ${item.align == 'left' ? '0px' : '9px'}; margin-right: ${item.align != 'left' ? '0px' : '9px'}">
											<div class="hch_flow_left_drop" style="background: ${item.change_color}">
												<div class="hch_flow_left_drop-inner" style="background: ${item.title_color}"></div>
											</div>
											<div class="hch_flow_left_line" style="background: ${item.line_color}"></div>
										</div>
										<div class="hch_flow_right">
											<div style="display: flex;justify-content: ${item.align == 'left' ? 'normal' : 'flex-end'}">
												<p class="hch_flow_right_title" style="background: ${item.title_color}">${ item.title }</p>
											</div>
											<p class="hch_flow_right_content" style="color: ${item.text_color},text-align: ${item.align == 'left' ? 'left' : 'right'}">${ item.text }</p>
										</div>
									</div>
								</div>`;
					});
					return `<div class="hch_flow " style="border-radius:${data.radius}px;box-shadow: ${data.shadow?'0 2px 10px 0 rgba(0,0,0,.15)':'none'};padding:${data.shadow?'16px':'0px'};width: 100%;">${_child_html}</div>`;
					break;
				case 303:
					_child_html=``;
					_child_html=`<div class="hch_audio" ref="audio" style=" border-radius: ${data.radius}px;width:${data.audio_width}%;border-width:0;">
						<div class="hch_audio_box">
							<div class="hch_audio_bg">
								<div class="mask" style=" border-radius: ${data.radius}px;background-color:rgba(0,0,0,${data.image_mask}%)"></div>
								<img v-if="${data.imgBool}" style=" border-radius: ${data.radius}px" src="${mediaFileAuthKeyJ1u2sx("image",data.cover.url)}" >
								<div class="hch_audio_cont">
									<p class="hch_audio_title" ref="hch_audio_title" style="color:${data.title_color}">${data.title}</p>
									<p class="hch_audio_name" ref="hch_audio_name" style="color:${data.name_color}">${data.name}</p>
									<div class="hch_audio_control">
										<div class="hch_audio_slider_box">
											<div style="background-color:${data.control_color};"></div>	
										</div>
										<div class="hch_audio_time">
											<span style="{color:${data.control_color}">00:00</span>
											<span style="{color:${data.control_color}">00:00</span>
										</div>
										<div class="hch_audio_button">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 31.25">
											  <path style="fill:${data.control_color}" d="M23.77,17.9L4.24,30.8c-.84,.55-1.92,.6-2.8,.12C.55,30.44,0,29.52,0,28.51V2.73C0,1.73,.55,.8,1.44,.33c.89-.48,1.96-.43,2.8,.13L23.77,13.34c.77,.51,1.23,1.36,1.23,2.28s-.46,1.78-1.23,2.28h0Z"/>
											</svg>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.38 22.38" style="position: absolute;right: 0;width: 22px;height: 22px;border-radius: 1px;">
											  <rect style="fill:${data.control_color}" class="cls-1" x="0" y="19.77" width="22.38" height="2.61" rx="1.31" ry="1.31"/>
											  <path style="fill:${data.control_color}" d="M18.05,10.57c0,.42-.17,.8-.45,1.09l-5.14,5.43c-.32,.33-.77,.54-1.28,.54s-.96-.21-1.27-.54l-5.15-5.42c-.27-.29-.44-.67-.44-1.09,0-.9,.77-1.63,1.71-1.63,.02,0,.03,0,.04,0h0s2.31-.01,2.31-.01V1.13c0-.62,.51-1.13,1.13-1.13h3.33c.62,0,1.13,.51,1.13,1.13v7.81h2.25s.06,0,.1,0c.95,0,1.72,.73,1.72,1.63Z"/>
											</svg>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>`;
					return _child_html;
				case 304:
					_child_html=`<div class="hch_link" ref="link" style=" border-radius: ${data.radius}px;width:100%;border-width:0;">
						<div class="hch_link_box">
							<div class="hch_link_content">
								<img src="${mediaFileAuthKeyJ1u2sx("image",data.cover.url)}" >
								<div class="hch_link_cont">
									<div class="hch_link_title_box">
										<p class="hch_link_title" ref="hch_link_title" style="color:${data.title_color}">${data.title}</p>
										<span class="iconfont icon-xiangyou1" style="font-size: 8px;"></span>
									</div>
									<p class="hch_link_describe" style="color:${data.describe_color}">${data.describe}</p>
								</div>
							</div>
						</div>
					</div>`;
					return _child_html;
				case 305:
					_child_html = ``
					data.lists.forEach((item,index)=>{
						_child_html += `<div class='hch_icon_link_box' style="border-bottom:${data.lists.length - index - 1 < (data.lists.length % 3 || 3)?'none':'0.5px solid #e6edef'}">
							<div class="hch_icon_content">
								<img style="filter:${item.filterBool?'grayscale(100%)':'none'}" src="${'image',item.cover.url}">
								<p class="hch_icon_title" style="color:${item.title_color}">${ item.title }</p>
							</div>
						</div>`
					})
					return `<div class="hch_icon_link" style="width:100%;border-width:0;">${_child_html}</div>`
				case 306:
					_child_html += ``
					_child_html = `<div class="hch_img_link" style="width:100%;border-width:0;">
							<div class="hch_img_link_box">
								<div class="hch_img_link_content">
										<div class="hch_img_link_left" style="padding:3px 4px;">
											<div class="hch_img_link__item">
												<img src="${mediaFileAuthKeyJ1u2sx("image",data.lists[0].cover.url)}" style="border-radius:${data.lists[0].radius}px">
												<p class="hch_img_link_title" style="color:${data.lists[0].title_color}">${data.lists[0].title}</p>
											</div>
										</div>
									<div class="hch_img_link_right">
											<div class="hch_img_link_right_top" style="padding:3px 4px;">
												<div class="hch_img_link__item">
													<img src="${mediaFileAuthKeyJ1u2sx("image",data.lists[1].cover.url)}" style="border-radius:${data.lists[1].radius}px">
													<p class="hch_img_link_title" style="{color:${data.lists[1].title_color}">${data.lists[1].title}</p>
												</div>
											</div>
										<div class="hch_img_link_right_bottom">
												<div class="hch_img_link_right_bottom_left" style="padding:3px 4px;">
													<div class="hch_img_link__item">
														<img src="${mediaFileAuthKeyJ1u2sx("image",data.lists[2].cover.url)}" style="border-radius:${data.lists[2].radius}px">
														<p class="hch_img_link_title" style="color:${data.lists[2].title_color};width:30%;">${data.lists[2].title}</p>
													</div>
												</div>
												<div class="hch_img_link_right_bottom_right" style="padding:3px 4px;">
													<div class="hch_img_link__item">
														<img src="${mediaFileAuthKeyJ1u2sx("image",data.lists[3].cover.url)}" style="border-radius:${data.lists[3].radius}px">
														<p class="hch_img_link_title" style="color:${data.lists[3].title_color};width:30%;">${data.lists[3].title}</p>
													</div>
												</div>
										</div>
									</div>
								</div>
							</div>
						</div>`
					return	_child_html
				case 307:
					_child_html = ``
					_child_html = `<div class="hch_img_link" style="width:100%;border-width:0;">
							<div class="hch_img_link_box">
								<div class="hch_img_link_content">
										<div class="hch_img_link_left" style="padding:3px 4px;">
												<div class="hch_img_link__item">
												<img src="${mediaFileAuthKeyJ1u2sx("image",data.lists[0].cover.url)}" style="border-radius:${data.lists[0].radius}px">
												<p class="hch_img_link_title" style="color:${data.lists[0].title_color}">${data.lists[0].title}</p>
											</div>
										</div>
									<div class="hch_img_link_right">
											<div class="hch_img_link_right_top" style="padding:3px 4px;">
												<div class="hch_img_link__item">
													<img src="${mediaFileAuthKeyJ1u2sx("image",data.lists[1].cover.url)}" style="border-radius:${data.lists[1].radius}px">
													<p class="hch_img_link_title" style="{color:${data.lists[1].title_color};">${data.lists[1].title}</p>
												</div>
											</div>
										<div class="hch_img_link_right_bottom" style="padding:3px 4px;">
												<div class="hch_img_link_right_bottom_left" style="width:100%;padding:0;">
													<div class="hch_img_link__item" style="padding-bottom:62%;">
														<img src="${mediaFileAuthKeyJ1u2sx("image",data.lists[2].cover.url)}" style="border-radius:${data.lists[2].radius}px">
														<p class="hch_img_link_title" style="color:${data.lists[2].title_color};">${data.lists[2].title}</p>
													</div>
												</div>
										</div>
									</div>
								</div>
							</div>
						</div>`
					return	_child_html
				case 308:
					_child_html = ``
					data.lists.forEach(item=>{
						_child_html += `<div style="width:38%;margin-right: 14px;">
						<div style="width:100%;">
							<div class="hch_img_text_link_box_item" style="padding-bottom: ${data.imgHeight*139/246}%">
								<img src="${mediaFileAuthKeyJ1u2sx("image",item.cover.url)}" style="border-radius:${data.media_radius}px;">
							</div>
							<p class="hch_img_text_link_box_title" style="color:${item.title_color}">${ item.title }</p>
						</div>
					</div>`
					})
					return `<div class="hch_img_text_link" style="width:100%;border-width:0;">
						<div style="display:${data.titleBool?'black':'none'}">
							<p class="hch_img_text_link_title" style="color:${data.title_color}">${ data.title }</p>
							<p class="hch_img_text_link_introduce" style="color:${data.introduce_color}">${ data.introduce }</p>
						</div>
						<div class="hch_img_text_link_box">
							${_child_html}
						</div>
					</div>`
				case 309:
					_child_html = ``
					data.lists.forEach(item=>{
						_child_html += `<div class="hch_video_list_box" style="padding:${data.shadow?'9px':'9px 0'}">
							<div class="hch_video_list_cover_box">
								<div style="width:25%;min-width: 100px;">
									<div style="position: relative;min-height:70px;">
										<img style="border-radius:${data.media_radius}px" src="${mediaFileAuthKeyJ1u2sx("image",item.cover.url)}">
										<div class="hch_card_audio_play">
											<i class="iconfont icon-a-tubiao_huaban11"></i>
										</div>
									</div>
								</div>
								<div class="hch_video_list_info">
									<p class="hch_video_list_title" ref="hch_video_list_title" style="color: ${item.title_color};margin-bottom:0px;">${ item.title }</p>
									<p class="hch_video_list_author" style="color:${item.author_color};margin-bottom:0px;">${ item.author }</p>
									<div class="hch_video_list_duration" style="color:${item.author_color};">
										<span><i class="iconfont icon-shijian1" style="font-size:9px;margin-right:5px;"></i> ${mediaFileAuthKeyJ1u2sx("time",item.video.duration)}</span>	
										<span style="margin-left: 20px;"><i class="iconfont icon-guankanrenshu" style="font-size:9px;margin-right:5px;"></i>${item.playNumber}</span>
									</div>
								</div>
							</div>
						</div>`
					})
					return `<div class="hch_video_list" style="border-radius: ${data.radius}px; box-shadow: ${data.shadow ? '0 2px 10px 0 rgba(0,0,0,.15)' : 'none'};padding:${data.shadow?'8px':'0px'};border-width:0;width:100%;" ref="video">${_child_html}</div>`
				case 310:
					_child_html = ``
					data.lists.forEach(item => {
						_child_html += `<div class="hch_card_audio_cover_box">
							<div class="hch_card_audio_content" style="position:relative;padding-bottom:${parseFloat(data.audioHeight)*100/161}%">
								<div style="width:100%;height:100%;position: absolute;left:0;right:0;">
									<img style="border-radius:${data.media_radius}px" src="${mediaFileAuthKeyJ1u2sx("image",item.cover.url)}">
								</div>
								<div class="hch_card_audio_play">
									<i class="iconfont icon-a-tubiao_huaban11"></i>
								</div>
							</div>
							<p class="hch_card_audio_item_title" style="color:${item.title_color}">${item.title}</p>
						</div>`
					});
					return `<div class="hch_card_audio" style="border-radius: ${data.radius}px;padding:${data.shadow?'33px 24px 27px':'0'};box-shadow: ${data.shadow ? '0 2px 10px 0 rgba(0,0,0,.15)' : 'none'};border-width:0;width:100%;">
						<div class="hch_card_audio_title" style="color:${data.title_color};padding-left:${data.shadow?'8px':0}">${data.title}</div>
						<div class="hch_card_audio_icon" style="background-color:${data.cardColor};display:${data.shadow?'black':'none'}"></div>
						<div class="hch_card_audio_box" style="width:${data.shadow?'100%':'calc(100% + 16px)'};margin-left:${data.shadow?'0':'-8px'}">${_child_html}</div>
					</div>`
				default:
					return _child_html;
					break;
			}
		}
	}
}
</script>

<style lang="less">
	.moudle-default-j2x .ant-modal-body{
		padding: 0;
	}
	.moudle-footer-box-bh1{
		width: 100%;
		padding: 10px 16px;
		background: transparent;
		border-top: 1px solid #f0f0f0;
		border-radius: 0 0 2px 2px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
		&__l{
			
		}
		&__r{
			
		}
	}
</style>
