<template>
<div class="hch_box">
		<div v-if="initData.data.tpl_id == 301" ref="module_301">
			<p class="add_module"><span style="margin-right: 19px;" @click="module_301.addBatch">添加一批</span><span @click="module_301.addGrid">添加一格</span></p>
			<div class="hch_flow_warp hch_box_scroll" :style="{maxHeight:contentMaxHeight+'px' }">
				<div class="hch_video" :style="{ borderRadius: initData.data.radius + 'px', boxShadow: initData.data.shadow ? '0 2px 10px 0 rgba(0,0,0,.15)' : 'none',padding:initData.data.shadow?'14px 15px':'0px'}" ref="video">
					<div :class="['hch_video_box',index==module_301.editIndex?'hch_video_box_selected':'']" v-for="(item, index) in initData.data.lists" :key="index" @click="module_301.selected(index)">
						<a-popover :visible="module_301.editIndex==index && module_301.editVisible" :mouseEnterDelay="0" :mouseLeaveDelay="0" trigger="click" placement="bottom" destroyTooltipOnHide autoAdjustOverflow>
							<template #content>
								<div class="hch_prover_edit">
									<span class="hch_prover_edit_span" @click="module_301.upVideo(index)">更换视频</span>
									<span class="hch_prover_edit_span" @click="module_301.upCover(index)">更换封面</span>
									<span class="hch_prover_edit_span" @click="module_301.hideCard">{{initData.data.shadow?'隐藏卡片':'显示卡片'}}</span>
									<a-popover v-if="initData.data.shadow" trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
										<template #content>
											<div style="display: flex;align-items: center;">
												<span style="color:#595959;">卡片圆角（px）</span><a-input-number id="inputNumber" :value="initData.data.radius" :min="0" :max="100" @change="module_301.changeRadius($event,'card')" />
												<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_301.getRadius('card')">确定</a-button>
											</div>
										</template>
										<span class="hch_prover_edit_span" @click="module_301.radiusVisible1=true;module_301.radiusVisible2=false;module_301.heightVisible=false">卡片圆角</span>
									</a-popover>
									<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
										<template #content>
											<div style="display: flex;align-items: center;">
												<span style="color:#595959;">视频圆角（px）</span><a-input-number id="inputNumber" :value="initData.data.media_radius" :min="0" :max="100" @change="module_301.changeRadius($event,'cover')" />
												<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_301.getRadius('cover')">确定</a-button>
											</div>
										</template>
										<span class="hch_prover_edit_span" @click="module_301.radiusVisible2=true;module_301.radiusVisible1=false;module_301.heightVisible=false">视频圆角</span>
									</a-popover>
									<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
										<template #content>
											<div style="display: flex;align-items: center;">
												<span style="color:#595959;">高度（px）</span><a-input-number id="inputNumber" :value="initData.data.cover_height" :min="0" :max="1000" @change="module_301.changeRadius($event,'height')" />
												<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;margin-right: 5px;" @click="module_301.getRadius('height')">确定</a-button>
												默认值:139.5
											</div>
										</template>
										<span class="hch_prover_edit_span" @click="module_301.heightVisible=true;module_301.radiusVisible2=false;module_301.radiusVisible1=false;">高度</span>
									</a-popover>
									<span class="hch_prover_edit_span" @click="module_301.moveLeft(index)" v-if="index%2!=0" style="font-weight: bold;">
										<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-arrow-l.svg">
									</span>
									<span class="hch_prover_edit_span" @click="module_301.moveRight(index)" v-if="index%2==0&&index!=initData.data.lists.length - 1" style="font-weight: bold;">
										<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-arrow-r.svg">
									</span>
									<span class="hch_prover_edit_span" @click="module_301.moveUp(index)" v-if="index != 0&&index != 1" style="font-weight: bold;">
										<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-arrow-t.svg">
									</span>
									<span class="hch_prover_edit_span" @click="module_301.moveDown(index)"  v-if="index != initData.data.lists.length - 1&&index != initData.data.lists.length - 2" style="font-weight: 700;">
										<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-arrow-b.svg">
									</span>
									<span class="hch_prover_edit_span" @click="module_301.addFlow(index)" style="font-weight: bold;">
										<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-plus.svg">
									</span>
									<span class="hch_prover_edit_span"><color-input class="colorpickerstyle" v-model="item.title_color" :style="'background:' + item.title_color" :left-show="true" :cancel-show="true" :disable-alpha="true" /></span>
									<span class="hch_prover_edit_span"><color-input class="colorpickerstyle" v-model="item.author_color" :style="'background:' + item.author_color" :left-show="true" :cancel-show="true" :disable-alpha="true" /></span>
									<span class="hch_prover_edit_span" @click="module_301.delFlow(index)">删除</span>
								</div>
							</template>
							<div style="width: 100%;position: relative;display: inline-block;">
								<div  class="hch_video_cover_box" :style="{borderRadius:initData.data.media_radius+'px',paddingBottom:((initData.data.cover_height/139.5)*56.25)+'%'}">
									<div class="loadingCon"  v-if="item.upload_hanlde.progress!=101&&!item.exchanging.exchanging">
										<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="item.upload_hanlde.progress" status="active" :show-info="false"/>
									</div>
									<div class="loadingCon" v-if="item.exchanging.exchanging">
										<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="item.exchanging.progress" status="active" :show-info="false"/>
										<p class="changeTime">转码中，预计还需要 {{item.exchanging.timeleft}} s</p>
									</div>
									<img :src="item.cover.url">
									<div class="hch_video_duration">{{ $baseFunc.converNumberToTime(item.video.duration) }}</div>
									<!-- <div class="hch_card_audio_play"><i class="iconfont icon-a-tubiao_huaban11"></i></div> -->
									<div class="hch_card_audio_play_j1hus"  @click="module_301.onPlay(index)"><svg t="1686026705607" class="icon" style="width: 1em;height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10188"><path d="M512 0C229.302144 0 0 229.302144 0 512s229.302144 512 512 512 512-229.302144 512-512S794.947368 0 512 0z" opacity=".5" p-id="10189" fill="#000000"></path><path d="M726.580897 545.933723l-290.931774 192.124757c-12.475634 8.233918-28.444444 8.982456-41.668616 1.746588a40.670565 40.670565 0 0 1-21.45809-35.929824V320.124756c0-14.97076 8.233918-28.693957 21.45809-35.929824a40.920078 40.920078 0 0 1 41.668616 1.746588l290.931774 192.124757c11.477583 7.48538 18.214425 20.210526 18.214425 33.933723s-6.986355 26.448343-18.214425 33.933723z" fill="#ffffff" p-id="10190"></path></svg></div>
								</div>
								<p class="hch_video_title" v-if="module_301.ellipsisBool" :style="{color:item.title_color}" @click="module_301.titleFocus(index)">{{ item.title }}</p>
								<input class="hch_video_title" ref="hch_video_title_inp" type="text" v-else :style="{color:item.title_color}" v-model="item.title" @blur="module_301.ellipsisBool=true">
								<p class="hch_video_author" ref="hch_video_author" :style="{color:item.author_color}" contenteditable="true" @keydown.enter.prevent @blur="module_301.getText($event,index,'author')">{{ item.author }}</p>
							</div>
						</a-popover>
					</div>
				</div>
			</div>
		</div>

		<div v-if="initData.data.tpl_id == 302" ref="module_302">
			<p class="add_module"><span style="margin-right: 19px;" @click="module_302.addBatch">添加一批</span><span @click="module_302.addGrid">添加一格</span></p>
			<div class="hch_flow_warp hch_box_scroll" :style="{maxHeight:contentMaxHeight+'px' }">
				<div class="hch_flow " ref="flow" :style="{ borderRadius: initData.data.radius + 'px', boxShadow: initData.data.shadow ? '0 2px 10px 0 rgba(0,0,0,.15)' : 'none',padding:initData.data.shadow?'16px':'0px'}">
					<div :class="['hch_flow_box', index == module_302.editIndex ? 'hch_flow_box_selected' : '']"
					v-for="(item, index) in initData.data.lists" :key="index" @click="module_302.selected(index)">
						<a-popover  :visible="module_302.editIndex==index && module_302.editVisible" trigger="click" placement="bottom" ref="popover" destroyTooltipOnHide>
							<template #content>
								<p class="hch_prover_edit">
									<span class="hch_prover_edit_span" @click="module_302.direction(index)">{{ item.align == 'left' ? '向右' : '向左' }}</span>
									<a-popover v-if="initData.data.shadow" :visible="index == module_302.editIndex && module_302.radiusVisible" trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
										<template #content>
											<div style="display: flex;align-items: center;">
												<span style="color:#595959;">圆角（px）</span><a-input-number id="inputNumber" :value="initData.data.radius" :min="0" :max="100" @change="module_302.changeRadius" />
												<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_302.getRadius">确定</a-button>
											</div>
										</template>
										<span class="hch_prover_edit_span" @click="module_302.radiusVisible=true">圆角</span>
									</a-popover>
									<!-- <span class="hch_prover_edit_span" @click="module_302.pave">左右铺满[{{initData.data.paveBool?'是':'否'}}]</span> -->
									<span @click="module_302.hideCard" class="hch_prover_edit_span">{{initData.data.shadow?'隐藏卡片':'显示卡片'}}</span>
									<span @click="module_302.moveUp(index)" class="hch_prover_edit_span" v-if="index != 0" style="font-weight: bold;"><img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-arrow-t.svg"></span>
									<span @click="module_302.moveDown(index)" class="hch_prover_edit_span"  v-if="index != this.initData.data.lists.length - 1" style="font-weight: 700;"><img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-arrow-b.svg"></span>
									<span @click="module_302.addFlow(index)" class="hch_prover_edit_span" style="font-weight: bold;"><img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-plus.svg"></span>
									<span class="hch_prover_edit_span"><color-input class="colorpickerstyle" v-model="item.title_color" :style="'background:' + item.title_color" :left-show="true" :cancel-show="true" :disable-alpha="true" @change="module_302.changeRoundColor(index)" /></span>
									<span class="hch_prover_edit_span"><color-input class="colorpickerstyle" v-model="item.text_color" :style="'background:' + item.text_color" :left-show="true" :cancel-show="true" :disable-alpha="true" /></span>
									<span class="hch_prover_edit_span"><color-input class="colorpickerstyle" v-model="item.line_color" :style="'background:' + item.line_color" :left-show="true" :cancel-show="true" :disable-alpha="true" /></span>
									<span class="hch_prover_edit_span" @click="module_302.delFlow(index)">删除</span>
								</p>
							</template>
							<div class="hch_prover" :style="{ flexDirection: item.align == 'left' ? 'row' : 'row-reverse' }">
								<div class="hch_flow_left"
									:style="{ marginLeft: item.align == 'left' ? '0px' : '9px', marginRight: item.align != 'left' ? '0px' : '9px' }">
									<div class="hch_flow_left_drop" :style="{background: item.change_color}">
										<div class="hch_flow_left_drop-inner" :style="{background: item.title_color}"></div>
									</div>
									<div class="hch_flow_left_line" :style="{background: item.line_color}"></div>
								</div>
								<div class="hch_flow_right">
									<div :style="{ display: 'flex', justifyContent: item.align == 'left' ? 'normal' : 'flex-end' }">
										<p ref="flow_right_title" class="hch_flow_right_title" :style="{background: item.title_color}" contenteditable="true" @keydown.enter.prevent @input="module_302.editHeight($event, index,'title')" @blur="module_302.getText($event,index,'title')">{{ item.title }}</p>
									</div>
									<p class="hch_flow_right_content" :style="{color: item.text_color,textAlign: item.align == 'left' ? 'left' : 'right'}" contenteditable="true" @input="module_302.editHeight($event, index)" @blur="module_302.getText($event,index)">{{ item.text }}</p>
								</div>
							</div>
						</a-popover>
					</div>
				</div>
			</div>
		</div>

		<div v-if="initData.data.tpl_id == 303" ref="module_303">
			<div class="hch_audio " ref="audio" :style="{ borderRadius: initData.data.radius + 'px',width:initData.data.audio_width+'%'}">
				<div class="hch_audio_box">
					<a-popover trigger="click" placement="bottom" ref="popover" destroyTooltipOnHide>
						<template #content>
							<p class="hch_prover_edit">
								<span class="hch_prover_edit_span" @click="module_303.upAudio">换音乐</span>
								<span class="hch_prover_edit_span" v-if="initData.data.imgBool" @click="module_303.upCover">换图</span>
								<a-popover v-if="initData.data.imgBool" trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
									<template #content>
										<div style="display: flex;align-items: center;">
											<span style="color:#595959;">暗度（%）</span><a-input-number id="inputNumber" :value="initData.data.image_mask" :min="0" :max="100" @change="module_303.changeRadius($event,'underlay')" />
											<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_303.getRadius('underlay')">确定</a-button>
										</div>
									</template>
									<span class="hch_prover_edit_span" @click="module_303.underlayVisible=true;module_303.radiusVisible=false;module_303.widthVisible=false;">底图变暗</span>
								</a-popover>
								<!-- <span class="hch_prover_edit_span" @click="module_303.hideImg">{{initData.data.imgBool?'隐藏底图':'显示底图'}}</span> -->
								<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
									<template #content>
										<div style="display: flex;align-items: center;">
											<span style="color:#595959;">圆角（px）</span><a-input-number id="inputNumber" :value="initData.data.radius" :min="0" :max="100" @change="module_303.changeRadius($event,'radius')" />
											<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_303.getRadius('radius')">确定</a-button>
										</div>
									</template>
									<span class="hch_prover_edit_span" @click="module_303.radiusVisible=true;module_303.underlayVisible=false;module_303.widthVisible=false;">圆角</span>
								</a-popover>
								<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
									<template #content>
										<div style="display: flex;align-items: center;">
											<span style="color:#595959;">宽度（百分比%）</span><a-input-number id="inputNumber" :value="initData.data.audio_width" :min="0" :max="100" @change="module_303.changeRadius($event,'width')" />
											<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_303.getRadius('width')">确定</a-button>
										</div>
									</template>
									<span class="hch_prover_edit_span" @click="module_303.widthVisible=true;module_303.radiusVisible=false;module_303.underlayVisible=false">宽度</span>
								</a-popover>
								<span class="hch_prover_edit_span"><color-input class="colorpickerstyle" v-model="initData.data.title_color" :style="'background:' + initData.data.title_color" :left-show="true" :cancel-show="true" :disable-alpha="true" /></span>
								<span class="hch_prover_edit_span"><color-input class="colorpickerstyle" v-model="initData.data.name_color" :style="'background:' + initData.data.name_color" :left-show="true" :cancel-show="true" :disable-alpha="true" /></span>
								<span class="hch_prover_edit_span"><color-input class="colorpickerstyle" v-model="initData.data.control_color" :style="'background:' + initData.data.control_color" :left-show="true" :cancel-show="true" :disable-alpha="true" @change="module_303.changeRoundColor()" /></span>
							</p>
						</template>
						<div class="hch_audio_bg">
							<div class="loadingCon"  v-if="initData.data.upload_hanlde.progress!=101&&!initData.data.exchanging.exchanging">
								<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="initData.data.upload_hanlde.progress" status="active" :show-info="false"/>
							</div>
							<div class="loadingCon" v-if="initData.data.exchanging.exchanging">
								<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="initData.data.exchanging.progress" status="active" :show-info="false"/>
								<p class="changeTime">转码中，预计还需要 {{initData.data.exchanging.timeleft}} s</p>
							</div>
							<div class="mask" :style="{ borderRadius: initData.data.radius + 'px',backgroundColor:`rgba(0,0,0,${initData.data.image_mask}%)`}"></div>
							<img v-if="initData.data.imgBool" :style="{ borderRadius: initData.data.radius + 'px'}" :src="$baseFunc.getAliOssAuthKey(initData.data.cover.url)" >
							<div class="hch_audio_cont">
								<p class="hch_audio_title" ref="hch_audio_title" :style="{color:initData.data.title_color}" contenteditable="true" @keydown.enter.prevent @input="module_303.editHeight($event,'title')" @blur="module_303.getText($event,'title')">{{initData.data.title}}</p>
								<p class="hch_audio_name" ref="hch_audio_name" :style="{color:initData.data.name_color}" contenteditable="true" @keydown.enter.prevent @input="module_303.editHeight($event,'name')" @blur="module_303.getText($event,'name')">{{initData.data.name}}</p>
								<div class="hch_audio_control">
									<a-slider class="hch_audio_slider" :style="{'--curentSlider':initData.data.control_color,'--changeColor':initData.data.change_color}" v-model:value="module_303.schedule" :tip-formatter="null" @afterChange="module_303.onAfterChange" @change="module_303.onAfterStop" />
									<div class="hch_audio_time">
										<span :style="{color:initData.data.control_color}">{{module_303.currentTime}}</span>
										<span :style="{color:initData.data.control_color}">{{module_303.totalDuration}}</span>
									</div>
									<div class="hch_audio_button">
										<svg @click="module_303.onPlay" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 31.25">
										  <path :style="{fill:initData.data.control_color}" d="M23.77,17.9L4.24,30.8c-.84,.55-1.92,.6-2.8,.12C.55,30.44,0,29.52,0,28.51V2.73C0,1.73,.55,.8,1.44,.33c.89-.48,1.96-.43,2.8,.13L23.77,13.34c.77,.51,1.23,1.36,1.23,2.28s-.46,1.78-1.23,2.28h0Z"/>
										</svg>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.38 22.38">
										  <rect :style="{fill:initData.data.control_color}" class="cls-1" x="0" y="19.77" width="22.38" height="2.61" rx="1.31" ry="1.31"/>
										  <path :style="{fill:initData.data.control_color}" d="M18.05,10.57c0,.42-.17,.8-.45,1.09l-5.14,5.43c-.32,.33-.77,.54-1.28,.54s-.96-.21-1.27-.54l-5.15-5.42c-.27-.29-.44-.67-.44-1.09,0-.9,.77-1.63,1.71-1.63,.02,0,.03,0,.04,0h0s2.31-.01,2.31-.01V1.13c0-.62,.51-1.13,1.13-1.13h3.33c.62,0,1.13,.51,1.13,1.13v7.81h2.25s.06,0,.1,0c.95,0,1.72,.73,1.72,1.63Z"/>
										</svg>
									</div>
								</div>
							</div>
						</div>
					</a-popover>
				</div>
			</div>
		</div>

		<div v-if="initData.data.tpl_id == 304" ref="module_304">
			<div class="hch_link" ref="link" :style="{ borderRadius: initData.data.radius + 'px'}">
				<div class="hch_link_box">
					<a-popover  trigger="click" placement="bottom" ref="popover" destroyTooltipOnHide>
						<template #title>
							<div class="link_title">
								<img src="/qrcode/gedit/modules/redirect/images/ico-link.svg">
								<span>{{initData.data.link?'['+(initData.data.link.type_name?initData.data.link.type_name:initData.data.link.type)+'] '+initData.data.link.desc:'未设置链接'}}</span>
							</div>
						</template>
						<template #content>
							<p class="hch_prover_edit">
								<span class="hch_prover_edit_span" @click="module_304.changeLink">换链接</span>
								<span class="hch_prover_edit_span" @click="module_304.upCover">换图</span>
								<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
									<template #content>
										<div style="display: flex;align-items: center;">
											<span style="color:#595959;">圆角（px）</span><a-input-number id="inputNumber" :value="initData.data.radius" :min="0" :max="100" @change="module_304.changeRadius($event,'radius')" />
											<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_304.getRadius('radius')">确定</a-button>
										</div>
									</template>
									<span class="hch_prover_edit_span" @click="module_304.radiusVisible=true">圆角</span>
								</a-popover>
								<span class="hch_prover_edit_span"><color-input class="colorpickerstyle" v-model="initData.data.title_color" :style="'background:' + initData.data.title_color" :left-show="true" :cancel-show="true" :disable-alpha="true" /></span>
								<span class="hch_prover_edit_span"><color-input class="colorpickerstyle" v-model="initData.data.describe_color" :style="'background:' + initData.data.describe_color" :left-show="true" :cancel-show="true" :disable-alpha="true" /></span>
							</p>
						</template>
						<div class="hch_link_content">
							<div style="position: relative;">
								<div class="loadingCon"  v-if="initData.data.upload_hanlde.progress!=101">
									<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="initData.data.upload_hanlde.progress" status="active" :show-info="false"/>
								</div>
								<img :src="$baseFunc.getAliOssAuthKey(initData.data.cover.url)" >
							</div>
							<div class="hch_link_cont">
								<div class="hch_link_title_box">
									<p class="hch_link_title" ref="hch_link_title" :style="{color:initData.data.title_color}" contenteditable="true" @blur="module_304.getText($event,'title')">{{initData.data.title}}</p>
									<span class="iconfont icon-xiangyou1" style="font-size: 8px;"></span>
								</div>
								<p class="hch_link_describe" ref="hch_link_describe" :style="{color:initData.data.describe_color}" contenteditable="true" @blur="module_304.getText($event,'name')">{{initData.data.describe}}</p>
							</div>
						</div>
					</a-popover>
				</div>
			</div>
		</div>

		<div v-if="initData.data.tpl_id == 305" ref="module_305">
			<div class="hch_flow_warp hch_box_scroll" :style="{maxHeight:contentMaxHeight+'px' }">
				<div class="hch_icon_link">
					<div class='hch_icon_link_box' v-for="(item, index) in initData.data.lists" :key="index"
					:style="{borderBottom:initData.data.lists.length - index - 1 < (initData.data.lists.length % 3 || 3)?'none':'0.5px solid #e6edef'}"
					@click="module_305.selected(index)">
						<a-popover :visible="index == module_305.editIndex&&item.upload_hanlde.progress==101" trigger="click" placement="bottom" ref="popover" destroyTooltipOnHide>
							<template #content>
							<p class="hch_prover_edit">
								<span class="hch_prover_edit_span" @click="module_305.upCover(index)">换图</span>
								<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
									<template #content>
										<div style="display: flex;align-items: center;">
											<span style="color:#595959;white-space: nowrap;marginRight:5px;">{{ item.title }}</span>
											<a-input class="hch_icon_inp_num" max="11" min="11" v-model:value.number="item.link.content" @blur="module_305.inpChange"/>
											<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_305.setBool = false">确定</a-button>
										</div>
									</template>
									<span class="hch_prover_edit_span" @click="module_305.setBool = true">设置{{item.title}}</span>
								</a-popover>
								<a-dropdown :trigger="['click']" placement="bottomCenter">
  							  <a class="ant-dropdown-link" @click.prevent>
  							    <span class="hch_prover_edit_span" style="margin-left:0;margin-right:23px;">添加项目</span>
  							  </a>
  							  <template #overlay>
  							    <a-menu>
  							      <a-menu-item v-for="items in module_305.projectList" :key="index" @click.stop="module_305.addFlow(items,index)">{{ items.title }}</a-menu-item>
  							    </a-menu>
  							  </template>
  							</a-dropdown>
								<span class="hch_prover_edit_span" @click="module_305.iconColorChange(index)">图标变灰</span>
								<span class="hch_prover_edit_span" @click="module_305.moveLeft(index)" v-if="index != 0" style="font-weight: bold;">
									<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-arrow-l.svg">
								</span>
								<span class="hch_prover_edit_span" @click="module_305.moveRight(index)" v-if="index != initData.data.lists.length - 1" style="font-weight: bold;">
									<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-arrow-r.svg">
								</span>
								<span class="hch_prover_edit_span">
									<color-input class="colorpickerstyle" v-model="item.title_color" :style="'background:' + item.title_color" :left-show="true" :cancel-show="true" 
									:disable-alpha="true" />
								</span>
								<span class="hch_prover_edit_span" @click="module_305.delFlow(index)">删除</span>
							</p>
							</template>
							<div :class="['hch_icon_content',index==module_305.editIndex?'hch_icon_link_selected':'']">
								<div class="loadingCon"  v-if="item.upload_hanlde.progress!=101">
									<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="item.upload_hanlde.progress" status="active" :show-info="false"/>
								</div>
								<img :style="{filter:item.filterBool?'grayscale(100%)':'none'}" :src="$baseFunc.getAliOssAuthKey(item.cover.url)">
								<p class="hch_icon_title" ref="hch_icon_title" :style="{color:item.title_color}" contenteditable="true" @keydown.enter.prevent @input="module_305.editHeight($event, index)" @blur="module_305.getText($event,index)">{{ item.title }}</p>
							</div>
						</a-popover>
					</div>
				</div>
			</div>
		</div>

		<div v-if="initData.data.tpl_id == 306" ref="module_306">
			<div class="hch_flow_warp hch_box_scroll" :style="{maxHeight:contentMaxHeight+'px' }">
				<div class="hch_img_link">
					<div class="hch_img_link_box">
						<div class="hch_img_link_content">
							<a-popover :visible="module_306.editIndex==0" trigger="click" placement="bottom" ref="popover" destroyTooltipOnHide>
								<template #title>
									<div class="link_title">
										<img src="/qrcode/gedit/modules/redirect/images/ico-link.svg">
										<span>{{initData.data.lists[0].link?'['+(initData.data.lists[0].link.type_name?initData.data.lists[0].link.type_name:initData.data.lists[0].link.type)+'] '+initData.data.lists[0].link.desc:'未设置链接'}}</span>
									</div>
								</template>
								<template #content>
									<p class="hch_prover_edit">
										<span class="hch_prover_edit_span" @click="module_306.changeLink(0)">换链接</span>
										<span class="hch_prover_edit_span" @click="module_306.upCover(0)">换图</span>
										<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
											<template #content>
												<div style="display: flex;align-items: center;">
													<span style="color:#595959;">圆角（px）</span><a-input-number id="inputNumber" :value="initData.data.media_radius" :min="0" :max="100" @change="module_306.changeRadius($event)" />
													<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_306.getRadius(0)">确定</a-button>
												</div>
											</template>
											<span class="hch_prover_edit_span" @click="module_306.radiusVisible=true">圆角</span>
										</a-popover>
										<span class="hch_prover_edit_span">
											<color-input class="colorpickerstyle" v-model="initData.data.lists[0].title_color" :style="'background:' + initData.data.lists[0].title_color" :left-show="true" :cancel-show="true" :disable-alpha="true" />
										</span>
									</p>
								</template>
								<div style="position: relative;" :class="['hch_img_link_left',module_306.editIndex==0?'hch_img_link_selected':'']" @click="module_306.selected(0)">
									<div class="loadingCon"  v-if="initData.data.lists[0].upload_hanlde.progress!=101">
										<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="initData.data.lists[0].upload_hanlde.progress" status="active" :show-info="false"/>
									</div>
									<div class="hch_img_link__item">
										<img :src="$baseFunc.getAliOssAuthKey(initData.data.lists[0].cover.url)" :style="{borderRadius:initData.data.media_radius+'px'}">
										<p class="hch_img_link_title" :style="{color:initData.data.lists[0].title_color}" contenteditable="true" @blur="module_306.getText($event,'title',0)">{{initData.data.lists[0].title}}</p>
									</div>
								</div>
							</a-popover>
							<div class="hch_img_link_right">
								<a-popover :visible="module_306.editIndex==1" trigger="click" placement="bottom" ref="popover" destroyTooltipOnHide>
									<template #title>
										<div class="link_title">
											<img src="/qrcode/gedit/modules/redirect/images/ico-link.svg">
											<span>{{initData.data.lists[1].link?'['+(initData.data.lists[1].link.type_name?initData.data.lists[1].link.type_name:initData.data.lists[1].link.type)+'] '+initData.data.lists[1].link.desc:'未设置链接'}}</span>
										</div>
									</template>
									<template #content>
										<p class="hch_prover_edit">
											<span class="hch_prover_edit_span" @click="module_306.changeLink(1)">换链接</span>
											<span class="hch_prover_edit_span" @click="module_306.upCover(1)">换图</span>
											<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
												<template #content>
													<div style="display: flex;align-items: center;">
														<span style="color:#595959;">圆角（px）</span><a-input-number id="inputNumber" :value="initData.data.media_radius" :min="0" :max="100" @change="module_306.changeRadius($event)" />
														<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_306.getRadius(1)">确定</a-button>
													</div>
												</template>
												<span class="hch_prover_edit_span" @click="module_306.radiusVisible=true">圆角</span>
											</a-popover>
											<span class="hch_prover_edit_span">
												<color-input class="colorpickerstyle" v-model="initData.data.lists[1].title_color" :style="'background:' + initData.data.lists[1].title_color" :left-show="true" :cancel-show="true" :disable-alpha="true" />
											</span>
										</p>
									</template>
									<div style="position: relative;" :class="['hch_img_link_right_top',module_306.editIndex==1?'hch_img_link_selected':'']"  @click="module_306.selected(1)">
										<div class="loadingCon"  v-if="initData.data.lists[1].upload_hanlde.progress!=101">
											<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="initData.data.lists[1].upload_hanlde.progress" status="active" :show-info="false"/>
										</div>
										<div class="hch_img_link__item">
											<img :src="$baseFunc.getAliOssAuthKey(initData.data.lists[1].cover.url)" :style="{borderRadius:initData.data.media_radius+'px'}">
											<p class="hch_img_link_title" :style="{color:initData.data.lists[1].title_color}" contenteditable="true" @blur="module_306.getText($event,'title',1)">{{initData.data.lists[1].title}}</p>
										</div>
									</div>
								</a-popover>
								<div class="hch_img_link_right_bottom">
									<a-popover :visible="module_306.editIndex==2" trigger="click" placement="bottom" ref="popover" destroyTooltipOnHide>
										<template #title>
											<div class="link_title">
												<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-link.svg">
												<span>{{initData.data.lists[2].link?'['+(initData.data.lists[2].link.type_name?initData.data.lists[2].link.type_name:initData.data.lists[2].link.type)+'] '+initData.data.lists[2].link.desc:'未设置链接'}}</span>
											</div>
										</template>
										<template #content>
											<p class="hch_prover_edit">
												<span class="hch_prover_edit_span" @click="module_306.changeLink(2)">换链接</span>
												<span class="hch_prover_edit_span" @click="module_306.upCover(2)">换图</span>
												<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
													<template #content>
														<div style="display: flex;align-items: center;">
															<span style="color:#595959;">圆角（px）</span><a-input-number id="inputNumber" :value="initData.data.media_radius" :min="0" :max="100" @change="module_306.changeRadius($event)" />
															<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_306.getRadius(2)">确定</a-button>
														</div>
													</template>
													<span class="hch_prover_edit_span" @click="module_306.radiusVisible=true">圆角</span>
												</a-popover>
												<span class="hch_prover_edit_span">
													<color-input class="colorpickerstyle" v-model="initData.data.lists[2].title_color" :style="'background:' + initData.data.lists[2].title_color" :left-show="true" :cancel-show="true" :disable-alpha="true" />
												</span>
											</p>
										</template>
										<div style="position: relative;" :class="['hch_img_link_right_bottom_left',module_306.editIndex==2?'hch_img_link_selected':'']"  @click="module_306.selected(2)">
											<div class="loadingCon"  v-if="initData.data.lists[2].upload_hanlde.progress!=101">
												<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="initData.data.lists[2].upload_hanlde.progress" status="active" :show-info="false"/>
											</div>
											<div class="hch_img_link__item">
												<img  :src="$baseFunc.getAliOssAuthKey(initData.data.lists[2].cover.url)" :style="{borderRadius:initData.data.media_radius+'px'}">
												<p class="hch_img_link_title" :style="{color:initData.data.lists[2].title_color}" contenteditable="true" @blur="module_306.getText($event,'title',2)">{{initData.data.lists[2].title}}</p>
											</div>
										</div>
									</a-popover>
									<a-popover :visible="module_306.editIndex==3" trigger="click" placement="bottom" ref="popover" destroyTooltipOnHide>
										<template #title>
											<div class="link_title">
												<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-link.svg">
												<span>{{initData.data.lists[3].link?'['+(initData.data.lists[3].link.type_name?initData.data.lists[3].link.type_name:initData.data.lists[3].link.type)+'] '+initData.data.lists[3].link.desc:'未设置链接'}}</span>
											</div>
										</template>
										<template #content>
											<p class="hch_prover_edit">
												<span class="hch_prover_edit_span" @click="module_306.changeLink(3)">换链接</span>
												<span class="hch_prover_edit_span" @click="module_306.upCover(3)">换图</span>
												<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
													<template #content>
														<div style="display: flex;align-items: center;">
															<span style="color:#595959;">圆角（px）</span><a-input-number id="inputNumber" :value="initData.data.media_radius" :min="0" :max="100" @change="module_306.changeRadius($event)" />
															<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_306.getRadius(3)">确定</a-button>
														</div>
													</template>
													<span class="hch_prover_edit_span" @click="module_306.radiusVisible=true">圆角</span>
												</a-popover>
												<span class="hch_prover_edit_span">
													<color-input class="colorpickerstyle" v-model="initData.data.lists[3].title_color" :style="'background:' + initData.data.lists[3].title_color" :left-show="true" :cancel-show="true" :disable-alpha="true" />
												</span>
											</p>
										</template>
										<div style="position: relative;" :class="['hch_img_link_right_bottom_right',module_306.editIndex==3?'hch_img_link_selected':'']" @click="module_306.selected(3)">
											<div class="loadingCon"  v-if="initData.data.lists[3].upload_hanlde.progress!=101">
												<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="initData.data.lists[3].upload_hanlde.progress" status="active" :show-info="false"/>
											</div>
											<div class="hch_img_link__item">
												<img  :src="$baseFunc.getAliOssAuthKey(initData.data.lists[3].cover.url)" :style="{borderRadius:initData.data.media_radius+'px'}">
												<p class="hch_img_link_title" :style="{color:initData.data.lists[3].title_color}" contenteditable="true" @blur="module_306.getText($event,'title',3)">{{initData.data.lists[3].title}}</p>
											</div>
										</div>
									</a-popover>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="initData.data.tpl_id == 307" ref="module_307">
			<div class="hch_flow_warp hch_box_scroll" :style="{maxHeight:contentMaxHeight+'px' }">
				<div class="hch_img_link">
					<div class="hch_img_link_box">
						<div class="hch_img_link_content">
							<a-popover :visible="module_307.editIndex==0" trigger="click" placement="bottom" ref="popover" destroyTooltipOnHide>
								<template #title>
									<div class="link_title">
										<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-link.svg">
										<span>{{initData.data.lists[0].link?'['+(initData.data.lists[0].link.type_name?initData.data.lists[0].link.type_name:initData.data.lists[0].link.type)+'] '+initData.data.lists[0].link.desc:'未设置链接'}}</span>
									</div>
								</template>
								<template #content>
									<p class="hch_prover_edit">
										<span class="hch_prover_edit_span" @click="module_307.changeLink(0)">换链接</span>
										<span class="hch_prover_edit_span" @click="module_307.upCover(0)">换图</span>
										<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
											<template #content>
												<div style="display: flex;align-items: center;">
													<span style="color:#595959;">圆角（px）</span><a-input-number id="inputNumber" :value="initData.data.media_radius" :min="0" :max="100" @change="module_307.changeRadius($event)" />
													<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_307.getRadius(0)">确定</a-button>
												</div>
											</template>
											<span class="hch_prover_edit_span" @click="module_307.radiusVisible=true">圆角</span>
										</a-popover>
										<span class="hch_prover_edit_span">
											<color-input class="colorpickerstyle" v-model="initData.data.lists[0].title_color" :style="'background:' + initData.data.lists[0].title_color" :left-show="true" :cancel-show="true" :disable-alpha="true" />
										</span>
									</p>
								</template>
								<div style="position: relative;" :class="['hch_img_link_left',module_307.editIndex==0?'hch_img_link_selected':'']" @click="module_307.selected(0)">
									<div class="loadingCon"  v-if="initData.data.lists[0].upload_hanlde.progress!=101">
										<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="initData.data.lists[0].upload_hanlde.progress" status="active" :show-info="false"/>
									</div>
									<div class="hch_img_link__item">
										<img :src="$baseFunc.getAliOssAuthKey(initData.data.lists[0].cover.url)" :style="{borderRadius:initData.data.media_radius+'px'}">
										<p class="hch_img_link_title" :style="{color:initData.data.lists[0].title_color,width:'auto'}" contenteditable="true" @blur="module_307.getText($event,'title',0)">{{initData.data.lists[0].title}}</p>
									</div>
								</div>
							</a-popover>
							<div class="hch_img_link_right">
								<a-popover :visible="module_307.editIndex==1" trigger="click" placement="bottom" ref="popover" destroyTooltipOnHide>
									<template #title>
										<div class="link_title">
											<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-link.svg">
											<span>{{initData.data.lists[1].link?'['+(initData.data.lists[1].link.type_name?initData.data.lists[1].link.type_name:initData.data.lists[1].link.type)+'] '+initData.data.lists[1].link.desc:'未设置链接'}}</span>
										</div>
									</template>
									<template #content>
										<p class="hch_prover_edit">
											<span class="hch_prover_edit_span" @click="module_307.changeLink(1)">换链接</span>
											<span class="hch_prover_edit_span" @click="module_307.upCover(1)">换图</span>
											<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
												<template #content>
													<div style="display: flex;align-items: center;">
														<span style="color:#595959;">圆角（px）</span><a-input-number id="inputNumber" :value="initData.data.media_radius" :min="0" :max="100" @change="module_307.changeRadius($event)" />
														<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_307.getRadius(1)">确定</a-button>
													</div>
												</template>
												<span class="hch_prover_edit_span" @click="module_307.radiusVisible=true">圆角</span>
											</a-popover>
											<span class="hch_prover_edit_span">
												<color-input class="colorpickerstyle" v-model="initData.data.lists[1].title_color" :style="'background:' + initData.data.lists[1].title_color" :left-show="true" :cancel-show="true" :disable-alpha="true" />
											</span>
										</p>
									</template>
									<div style="position: relative;" :class="['hch_img_link_right_top',module_307.editIndex==1?'hch_img_link_selected':'']" @click="module_307.selected(1)">
										<div class="loadingCon"  v-if="initData.data.lists[1].upload_hanlde.progress!=101">
											<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="initData.data.lists[1].upload_hanlde.progress" status="active" :show-info="false"/>
										</div>
										<div class="hch_img_link__item">
											<img :src="$baseFunc.getAliOssAuthKey(initData.data.lists[1].cover.url)" :style="{borderRadius:initData.data.media_radius+'px'}">
											<p class="hch_img_link_title" :style="{color:initData.data.lists[1].title_color,width:'auto'}" contenteditable="true" @blur="module_307.getText($event,'title',1)">{{initData.data.lists[1].title}}</p>
										</div>
									</div>
								</a-popover>
								<div :class="['hch_img_link_right_bottom',module_307.editIndex==2?'hch_img_link_selected':'']" style="padding: 6px 8px;">
									<a-popover :visible="module_307.editIndex==2" trigger="click" placement="bottom" ref="popover" destroyTooltipOnHide>
										<template #title>
											<div class="link_title">
												<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-link.svg">
												<span>{{initData.data.lists[2].link?'['+(initData.data.lists[2].link.type_name?initData.data.lists[2].link.type_name:initData.data.lists[2].link.type)+'] '+initData.data.lists[2].link.desc:'未设置链接'}}</span>
											</div>
										</template>
										<template #content>
											<p class="hch_prover_edit">
												<span class="hch_prover_edit_span" @click="module_307.changeLink(2)">换链接</span>
												<span class="hch_prover_edit_span" @click="module_307.upCover(2)">换图</span>
												<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
													<template #content>
														<div style="display: flex;align-items: center;">
															<span style="color:#595959;">圆角（px）</span><a-input-number id="inputNumber" :value="initData.data.media_radius" :min="0" :max="100" @change="module_307.changeRadius($event)" />
															<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_307.getRadius(2)">确定</a-button>
														</div>
													</template>
													<span class="hch_prover_edit_span" @click="module_307.radiusVisible=true">圆角</span>
												</a-popover>
												<span class="hch_prover_edit_span">
													<color-input class="colorpickerstyle" v-model="initData.data.lists[2].title_color" :style="'background:' + initData.data.lists[2].title_color" :left-show="true" :cancel-show="true" :disable-alpha="true" />
												</span>
											</p>
										</template>
										<div class="hch_img_link_right_bottom_left" style="width:100%;padding:0;position: relative;" @click="module_307.selected(2)">
											<div class="loadingCon"  v-if="initData.data.lists[2].upload_hanlde.progress!=101">
												<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="initData.data.lists[2].upload_hanlde.progress" status="active" :show-info="false"/>
											</div>
											<div class="hch_img_link__item" style="padding-bottom:62%;">
												<img :src="$baseFunc.getAliOssAuthKey(initData.data.lists[2].cover.url)" :style="{borderRadius:initData.data.media_radius+'px'}">
												<p class="hch_img_link_title" :style="{color:initData.data.lists[2].title_color,width:'auto'}" contenteditable="true" @blur="module_307.getText($event,'title',2)">{{initData.data.lists[2].title}}</p>
											</div>
										</div>
									</a-popover>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="initData.data.tpl_id == 308" ref="module_308">
			<div class="hch_flow_warp hch_box_scroll" :style="{maxHeight:contentMaxHeight+'px' }">
				<div class="hch_img_text_link">
					<p class="add_module"><span style="margin-right: 19px;" @click="module_301.addBatch">添加一批</span><span @click="module_301.addGrid">添加一格</span></p>
					<div v-if="initData.data.titleBool">
						<p class="hch_img_text_link_title" :style="{color:initData.data.title_color}" contenteditable="true" @blur="module_308.getText($event,'title')">{{ initData.data.title }}</p>
						<p class="hch_img_text_link_introduce" :style="{color:initData.data.introduce_color}" contenteditable="true" @blur="module_308.getText($event,'introduce')">{{ initData.data.introduce }}</p>
					</div>
					<div class="hch_img_text_link_box">
						<div v-for="(item,index) in initData.data.lists" style="width:38%;margin-right: 14px;" @click="module_308.selected(index)">
							<a-popover :visible="module_308.editIndex==index && module_308.editVisible" trigger="click" placement="bottom" ref="popover" destroyTooltipOnHide>
							<template #content>
								<p class="hch_prover_edit">
									<span class="hch_prover_edit_span" @click="module_308.upCover(index)">换图</span>
									<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
										<template #content>
											<div style="display: flex;align-items: center;">
												<span style="color:#595959;">圆角（px）</span><a-input-number id="inputNumber" :value="initData.data.media_radius" :min="0" :max="100" @change="module_308.changeRadius($event)" />
												<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_308.getRadius(index)">确定</a-button>
											</div>
										</template>
										<span class="hch_prover_edit_span" @click="module_308.radiusVisible=true;module_308.heightVisible=false;">圆角</span>
									</a-popover>
									<span class="hch_prover_edit_span" @click="module_308.changeTitle(index)">{{initData.data.titleBool?'隐藏标题':'显示标题'}}</span>
									<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
										<template #content>
											<div style="display: flex;align-items: center;">
												<span style="color:#595959;">高度（px）</span><a-input-number id="inputNumber" :value="initData.data.imgHeight" :min="0" :max="1000" @change="module_308.changeRadius($event,'height')" />
												<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;margin-right: 5px;" @click="module_308.getRadius(index,'height')">确定</a-button>
												默认值:246
											</div>
										</template>
										<span class="hch_prover_edit_span" @click="module_308.heightVisible=true;module_308.radiusVisible=false;">高度</span>
									</a-popover>
									<span class="hch_prover_edit_span" @click="module_308.moveLeft(index)" v-if="index != 0" style="font-weight: bold;">
										<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-arrow-l.svg">
									</span>
									<span class="hch_prover_edit_span" @click="module_308.moveRight(index)" v-if="index != initData.data.lists.length - 1" style="font-weight: bold;">
										<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-arrow-r.svg">
									</span>
									<span class="hch_prover_edit_span" @click="module_308.addFlow(index)" style="font-weight: bold;">
										<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-plus.svg">
									</span>
									<span class="hch_prover_edit_span">
										<color-input class="colorpickerstyle" v-model="initData.data.title_color" :style="'background:' + initData.data.title_color" :left-show="true" :cancel-show="true" :disable-alpha="true" />
									</span>
									<span class="hch_prover_edit_span">
										<color-input class="colorpickerstyle" v-model="initData.data.introduce_color" :style="'background:' + initData.data.introduce_color" :left-show="true" :cancel-show="true" :disable-alpha="true" />
									</span>
									<span class="hch_prover_edit_span">
										<color-input class="colorpickerstyle" v-model="item.title_color" :style="'background:' + item.title_color" :left-show="true" :cancel-show="true" :disable-alpha="true" />
									</span>
									<span class="hch_prover_edit_span" @click="module_308.delFlow(index)">删除</span>
								</p>
							</template>
							<div :class="index==module_308.editIndex?'hch_img_text_link_box_selected':''" style="width:100%;">
								<div class="hch_img_text_link_box_item" :style="{paddingBottom: (initData.data.imgHeight*139/246)+'%'}">
									<div class="loadingCon" :style="{borderRadius:item.radius+'px'}" v-if="item.upload_hanlde.progress!=101">
										<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="item.upload_hanlde.progress" status="active" :show-info="false"/>
									</div>
									<img :src="$baseFunc.getAliOssAuthKey(item.cover.url)" :style="{borderRadius:initData.data.media_radius+'px'}" >
								</div>
								<p class="hch_img_text_link_box_title" v-if="module_308.ellipsisBool" :style="{color:item.title_color}" @click="module_308.titleFocus(index)">{{ item.title }}</p>
								<input class="hch_img_text_link_box_title" ref="hch_img_text_link_box_title_inp" type="text" v-else :style="{color:item.title_color}" v-model="item.title" @blur="module_308.ellipsisBool=true">
							</div>
							</a-popover>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="initData.data.tpl_id == 309" ref="module_309">
			<p class="add_module"><span style="margin-right: 19px;" @click="module_309.addBatch">添加一批</span><span @click="module_309.addGrid">添加一格</span></p>
			<div class="hch_flow_warp hch_box_scroll" :style="{maxHeight:contentMaxHeight+'px' }">
				<div class="hch_video_list" :style="{ borderRadius: initData.data.radius + 'px', boxShadow: initData.data.shadow ? '0 2px 10px 0 rgba(0,0,0,.15)' : 'none',padding:initData.data.shadow?'8px':'0px'}" ref="video">
					<div :class="['hch_video_list_box',index==module_309.editIndex?'hch_video_list_box_selected':'']" :style="{padding:initData.data.shadow?'9px':'9px 0'}" v-for="(item, index) in initData.data.lists" :key="index" @click="module_309.selected(index)">
						<a-popover :visible="module_309.editIndex==index && module_309.editVisible" trigger="click" placement="bottom" ref="popover" destroyTooltipOnHide>
							<template #content>
							<p class="hch_prover_edit">
								<span class="hch_prover_edit_span" @click="module_309.upVideo(index)">视频上传</span>
								<span class="hch_prover_edit_span" @click="module_309.upCover(index)">更换封面</span>
								<span class="hch_prover_edit_span" @click="module_309.hideCard">{{initData.data.shadow?'隐藏卡片':'显示卡片'}}</span>
								<a-popover v-if="initData.data.shadow"  trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
									<template #content>
										<div style="display: flex;align-items: center;">
											<span style="color:#595959;">圆角（px）</span><a-input-number id="inputNumber" :value="initData.data.radius" :min="0" :max="100" @change="module_309.changeRadius($event,'card')" />
											<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_309.getRadius('card')">确定</a-button>
										</div>
									</template>
									<span class="hch_prover_edit_span">卡片圆角</span>
								</a-popover>
								<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
									<template #content>
										<div style="display: flex;align-items: center;">
											<span style="color:#595959;">圆角（px）</span><a-input-number id="inputNumber" :value="initData.data.media_radius" :min="0" :max="100" @change="module_309.changeRadius($event,'cover')" />
											<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_309.getRadius('cover')">确定</a-button>
										</div>
									</template>
									<span class="hch_prover_edit_span">图片圆角</span>
								</a-popover>
								<span class="hch_prover_edit_span" @click="module_309.moveUp(index)" v-if="index != 0" style="font-weight: bold;">
									<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-arrow-t.svg">
								</span>
								<span class="hch_prover_edit_span" @click="module_309.moveDown(index)"  v-if="index != initData.data.lists.length - 1" style="font-weight: 700;">
									<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-arrow-b.svg">
								</span>
								<span class="hch_prover_edit_span" @click="module_309.addFlow(index)" style="font-weight: bold;">
									<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-plus.svg">
								</span>
								<span class="hch_prover_edit_span"><color-input class="colorpickerstyle" v-model="item.title_color" :style="'background:' + item.title_color" :left-show="true" :cancel-show="true" :disable-alpha="true" /></span>
								<span class="hch_prover_edit_span"><color-input class="colorpickerstyle" v-model="item.author_color" :style="'background:' + item.author_color" :left-show="true" :cancel-show="true" :disable-alpha="true" /></span>
								<span class="hch_prover_edit_span" @click="module_309.delFlow(index)">删除</span>
							</p>
							</template>
							<div class="hch_video_list_cover_box">
								<div style="width:25%;">
									<div class="loadingCon"  v-if="item.upload_hanlde.progress!=101&&!item.exchanging.exchanging">
										<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="item.upload_hanlde.progress" status="active" :show-info="false"/>
									</div>
									<div class="loadingCon" v-if="item.exchanging.exchanging">
										<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="item.exchanging.progress" status="active" :show-info="false"/>
										<p class="changeTime" style="bottom: 5px;">转码中，预计还需要{{item.exchanging.timeleft}}S</p>
									</div>
									<div style="position: relative;min-height:70px;width: 100%;padding-bottom: 70%;">
										
										<div class="hch_card_audio_play_j1hus" @click="module_309.onPlay(index)"><svg t="1686026705607" class="icon" style="width: 1em;height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10188"><path d="M512 0C229.302144 0 0 229.302144 0 512s229.302144 512 512 512 512-229.302144 512-512S794.947368 0 512 0z" opacity=".5" p-id="10189" fill="#000000"></path><path d="M726.580897 545.933723l-290.931774 192.124757c-12.475634 8.233918-28.444444 8.982456-41.668616 1.746588a40.670565 40.670565 0 0 1-21.45809-35.929824V320.124756c0-14.97076 8.233918-28.693957 21.45809-35.929824a40.920078 40.920078 0 0 1 41.668616 1.746588l290.931774 192.124757c11.477583 7.48538 18.214425 20.210526 18.214425 33.933723s-6.986355 26.448343-18.214425 33.933723z" fill="#ffffff" p-id="10190"></path></svg></div>
										<!-- <div class="hch_card_audio_play" @click="module_309.onPlay(index)"><i class="iconfont icon-a-tubiao_huaban11"></i></div> -->
										<img :style="{borderRadius:initData.data.media_radius+'px'}" :src="$baseFunc.getAliOssAuthKey(item.cover.url)">
									</div>
								</div>
								<div class="hch_video_list_info">
									<p class="hch_video_list_title" ref="hch_video_list_title" :style="{color: item.title_color}" contenteditable="true" @keydown.enter.prevent @input="module_309.editHeight($event, index,'title')" @blur="module_302.getText($event,index,'title')">{{ item.title }}</p>
									<p class="hch_video_list_author" v-if="module_309.ellipsisBool" :style="{color:item.author_color}" @click="module_309.titleFocus(index)">{{ item.author }}</p>
									<input class="hch_video_list_author" ref="hch_video_list_author_inp" type="text" v-else :style="{color:item.author_color}" v-model="item.author" @blur="module_309.ellipsisBool=true">
									<div class="hch_video_list_duration" :style="{color:item.author_color}">
										<span><i class="iconfont icon-shijian1" style="font-size:9px;margin-right:5px;"></i> {{$baseFunc.converNumberToTime(item.video.duration)}}</span>	
										<!-- <span style="margin-left: 20px;"><i class="iconfont icon-guankanrenshu" style="font-size:9px;margin-right:5px;"></i>{{item.playNumber}}</span> -->
									</div>
								</div>
							</div>
						</a-popover>
					</div>
				</div>
			</div>
		</div>

		<div v-if="initData.data.tpl_id == 310" ref="module_310">
			<div class="hch_flow_warp hch_box_scroll" :style="{maxHeight:contentMaxHeight+'px' }">
				<div class="hch_card_audio" :style="{ borderRadius: initData.data.radius + 'px',padding:initData.data.shadow?'33px 24px 27px':'0', boxShadow: initData.data.shadow ? '0 2px 10px 0 rgba(0,0,0,.15)' : 'none'}" ref="video">
					<div class="hch_card_audio_title" :style="{color:initData.data.title_color,paddingLeft:initData.data.shadow?'8px':0}" contenteditable="true" @keydown.enter.prevent @blur="module_310.getText($event,-1,'title')">{{initData.data.title}}</div>
					<div class="hch_card_audio_icon" :style="{backgroundColor:initData.data.cardColor}" v-if="initData.data.shadow"></div>
					<div class="hch_card_audio_box" :style="{width:initData.data.shadow?'100%':'calc(100% + 16px)',marginLeft:initData.data.shadow?'0':'-8px'}">
						<div :class="['hch_card_audio_cover_box',index==module_310.editIndex?'hch_card_audio_cover_box_selected':'']" v-for="(item,index) in initData.data.lists"  @click="module_310.selected(index)">
							<a-popover :visible="module_310.editIndex==index && module_310.editVisible" trigger="click" placement="bottom" ref="popover" destroyTooltipOnHide>
								<template #content>
								<p class="hch_prover_edit">
									<span class="hch_prover_edit_span" @click="module_310.upAudio(index)">更换音频</span>
									<span class="hch_prover_edit_span" @click="module_310.upCover(index)">更换封面</span>
									<span class="hch_prover_edit_span" @click="module_310.hideCard">{{initData.data.shadow?'隐藏卡片':'显示卡片'}}</span>
									<a-popover v-if="initData.data.shadow" trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
										<template #content>
											<div style="display: flex;align-items: center;">
												<span style="color:#595959;">圆角（px）</span><a-input-number id="inputNumber" :value="initData.data.radius" :min="0" :max="100" @change="module_310.changeRadius($event,'card')" />
												<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_310.getRadius('card')">确定</a-button>
											</div>
										</template>
										<span class="hch_prover_edit_span">卡片圆角</span>
									</a-popover>
									<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
										<template #content>
											<div style="display: flex;align-items: center;">
												<span style="color:#595959;">圆角（px）</span><a-input-number id="inputNumber" :value="initData.data.media_radius" :min="0" :max="100" @change="module_310.changeRadius($event,'cover')" />
												<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_310.getRadius('cover')">确定</a-button>
											</div>
										</template>
										<span class="hch_prover_edit_span">音频圆角</span>
									</a-popover>
									<a-popover trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
										<template #content>
											<div style="display: flex;align-items: center;">
												<span style="color:#595959;">高度（px）</span><a-input-number id="inputNumber" :value="initData.data.audioHeight" :min="0" :max="1000" @change="module_310.changeRadius($event,'height')" />
												<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;margin-right: 5px;" @click="module_310.getRadius('height')">确定</a-button>
												默认值:161
											</div>
										</template>
										<span class="hch_prover_edit_span">高度</span>
									</a-popover>
									<span class="hch_prover_edit_span" @click="module_310.moveUp(index)" v-if="index != 0" style="font-weight: bold;">
										<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-arrow-l.svg">
									</span>
									<span class="hch_prover_edit_span" @click="module_310.moveDown(index)"  v-if="index != initData.data.lists.length - 1" style="font-weight: 700;">
										<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-arrow-r.svg">
									</span>
									<span class="hch_prover_edit_span">
										<color-input class="colorpickerstyle" v-model="initData.data.cardColor" :style="'background:' + initData.data.cardColor" :left-show="true" :cancel-show="true" :disable-alpha="true" />
									</span>
									<span class="hch_prover_edit_span">
										<color-input class="colorpickerstyle" v-model="initData.data.title_color" :style="'background:' + initData.data.title_color" :left-show="true" :cancel-show="true" :disable-alpha="true" />
									</span>
									<span class="hch_prover_edit_span">
										<color-input class="colorpickerstyle" v-model="item.title_color" :style="'background:' + item.title_color" :left-show="true" :cancel-show="true" :disable-alpha="true" />
									</span>
								</p>
								</template>
								<div>
									<div class="hch_card_audio_content" :style="{position: 'relative',paddingBottom:(parseFloat(initData.data.audioHeight)*100/161)+'%'}">
										<div style="width:100%;height:100%;position: absolute;left:0;right:0;">
											<img :style="{borderRadius:initData.data.media_radius+'px'}" :src="$baseFunc.getAliOssAuthKey(item.cover.url)">
											<div class="loadingCon" :style="{borderRadius:initData.data.media_radius+'px'}" v-if="item.upload_hanlde.progress!=101&&!item.exchanging.exchanging">
												<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="item.upload_hanlde.progress" status="active" :show-info="false"/>
											</div>
											<div class="loadingCon" :style="{borderRadius:initData.data.media_radius+'px'}" v-if="item.exchanging.exchanging">
												<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="item.exchanging.progress" status="active" :show-info="false"/>
												<p class="changeTime">转码中，预计还需要 {{item.exchanging.timeleft}} s</p>
											</div>
										</div>
										<div class="hch_card_audio_play_j1hus" @click="module_310.onPlay(index)"><svg t="1686026705607" class="icon" style="width: 1em;height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10188"><path d="M512 0C229.302144 0 0 229.302144 0 512s229.302144 512 512 512 512-229.302144 512-512S794.947368 0 512 0z" opacity=".5" p-id="10189" fill="#000000"></path><path d="M726.580897 545.933723l-290.931774 192.124757c-12.475634 8.233918-28.444444 8.982456-41.668616 1.746588a40.670565 40.670565 0 0 1-21.45809-35.929824V320.124756c0-14.97076 8.233918-28.693957 21.45809-35.929824a40.920078 40.920078 0 0 1 41.668616 1.746588l290.931774 192.124757c11.477583 7.48538 18.214425 20.210526 18.214425 33.933723s-6.986355 26.448343-18.214425 33.933723z" fill="#ffffff" p-id="10190"></path></svg></div>
										<!-- <div class="hch_card_audio_play" @click="module_310.onPlay(index)"><i class="iconfont icon-a-tubiao_huaban11"></i></div> -->
									</div>
									<p class="hch_card_audio_item_title" :style="{color:item.title_color}" contenteditable="true" @keydown.enter.prevent @blur="module_310.getText($event,index,'title')">{{item.title}}</p>
								</div>
							</a-popover>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="initData.data.tpl_id == 311" ref="module_311">
			<p class="add_module"><span style="margin-right: 19px;" @click="module_311.addBatch">添加一批</span><span @click="module_311.addGrid">添加一格</span></p>
			<div class="hch_flow_warp hch_box_scroll" :style="{maxHeight:contentMaxHeight+'px' }">
				<div class="hch_audio_list" :style="{ borderRadius: initData.data.radius + 'px', boxShadow: initData.data.shadow ? '0 2px 10px 0 rgba(0,0,0,.15)' : 'none',padding:initData.data.shadow?'8px':'0px'}">
					<div :class="['hch_audio_list_box',index==module_311.editIndex?'hch_audio_list_box_selected':'']" v-for="(item, index) in initData.data.lists" :key="index" @click="module_311.selected(index)">
						<a-popover :visible="index == module_311.editIndex&&item.upload_hanlde.progress==101" trigger="click" placement="bottom" ref="popover" destroyTooltipOnHide>
							<template #content>
							<p class="hch_prover_edit">
								<span class="hch_prover_edit_span" @click="module_311.upAudio(index)">换音频</span>
								<span class="hch_prover_edit_span" @click="module_311.upCover(index)">更换封面</span>
								<a-popover :visible="index == module_311.editIndex && module_311.radiusVisible" trigger="click" placement="bottom" overlayClassName="fillet_popover" destroyTooltipOnHide>
									<template #content>
										<div style="display: flex;align-items: center;">
											<span style="color:#595959;">圆角（px）</span><a-input-number id="inputNumber" :value="item.radius" :min="0" :max="100" @change="module_311.changeRadius($event,'cover')" />
											<a-button type="primary" style="margin-left: 5px;padding-left: 10px;padding-right: 10px;" @click="module_311.getRadius('cover')">确定</a-button>
										</div>
									</template>
									<span class="hch_prover_edit_span" @click="module_311.radiusVisible=true;">圆角</span>
								</a-popover>
								<span class="hch_prover_edit_span" @click="module_311.moveUp(index)" v-if="index != 0" style="font-weight: bold;">
									<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-arrow-t.svg">
								</span>
								<span class="hch_prover_edit_span" @click="module_311.moveDown(index)"  v-if="index != initData.data.lists.length - 1" style="font-weight: 700;">
									<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-arrow-b.svg">
								</span>
								<span class="hch_prover_edit_span" @click="module_311.addFlow(index)" style="font-weight: bold;">
									<img src="https://bianji.wwei.cn/qrcode/gedit/modules/redirect/images/ico-plus.svg">
								</span>
								<span class="hch_prover_edit_span">
									<color-input class="colorpickerstyle" v-model="item.title_color" :style="'background:' + item.title_color" :left-show="true" :cancel-show="true" :disable-alpha="true" />
								</span>
								<span class="hch_prover_edit_span">
									<color-input class="colorpickerstyle" v-model="item.author_color" :style="'background:' + item.author_color" :left-show="true" :cancel-show="true" :disable-alpha="true" />
								</span>
								<span class="hch_prover_edit_span">
									<color-input class="colorpickerstyle" v-model="item.schedule_color" :style="'background:' + item.schedule_color" :left-show="true" :cancel-show="true" :disable-alpha="true" />
								</span>
								<span class="hch_prover_edit_span" @click="module_311.delFlow(index)">删除</span>
							</p>
							</template>
							<div class="hch_audio_list_content">
								<div class="loadingCon"  v-if="item.upload_hanlde.progress!=101&&!item.exchanging.exchanging">
									<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="item.upload_hanlde.progress" status="active" :show-info="false"/>
								</div>
								<div class="loadingCon" v-if="item.exchanging.exchanging">
									<a-progress :strokeWidth="6" strokeColor="#09bd71" trailColor="white" :percent="item.exchanging.progress" status="active" :show-info="false"/>
									<p class="changeTime" style="bottom:20px;">转码中，预计还需要{{item.exchanging.timeleft}}S</p>
								</div>
								<div class="hch_audio_item_play">
									<img src="/img/module/bj4.png" :style="{borderRadius:item.radius+'px'}" >
									<div class="hch_audio_list_play" @click="module_311.playBool?module_311.onPause(index):module_311.onPlay(index)">
										<img :src="module_311.playBool&&index==module_311.editIndex?'/img/module/pause.png':'/img/module/play1.png'">
									</div>
								</div>
								<div class="hch_audio_item_text">
									<p class="hch_audio_item_title" :style="{color:item.title_color}">{{item.title}}</p>
									<p class="hch_audio_item_author" :style="{color:item.author_color}">{{ item.author }}</p>
									<div class="hch_audio_item_schedule">
										<a-slider class="hch_audio_slider" :style="{'--curentSlider':item.schedule_color}"  :tip-formatter="null" v-model:value="item.schedule"  @afterChange="module_311.onAfterChange" @change="module_311.onAfterStop" />
										<span>{{item.currentTime}}/{{$baseFunc.converNumberToTime(item.audio.duration)}}</span>
									</div>
								</div>
							</div>
						</a-popover>
					</div>
				</div>
			</div>
		</div>

		<!-- 媒体选择组件 -->
		<input v-for="(item, index) in upVideoModuleList" :key="item.id" :ref="`addSingleVideoRef${item.id}`" type="file" accept="video/*" @change="item.addSingleVideoChange($event)" v-show="false" />
		<input v-for="(item, index) in upAudioModuleList" :key="item.id" :ref="`addSingleAudioRef${item.id}`" type="file" accept="audio/*" @change="item.addSingleAudioChange($event)" v-show="false" />
		<input v-for="(item, index) in upCoverModuleList" :key="item.id" :ref="`addSingleImageRef${item.id}`" type="file" accept="image/*" @change="item.addSingleImageChange($event)" v-show="false" />
		<!-- 预览文件model -->
		<a-modal :zIndex="2001" class="mbm_player_model" :title="playModal.pageType==1?'视频':'音频'"
			:visible="playModal.visible"  @cancel="playModal.cancel" :footer="null" :destroyOnClose="true" :width="600">
			<player :pageType="playModal.pageType" :info="playModal.info"></player>
		</a-modal>
	</div>
</template>

<script>
import { message } from 'ant-design-vue';
import ColorInput from '/src/components/color-picker/color-input.vue';
import player from '/src/components/player.vue';
export default {
	name: 'ModuleLibrary',
	components:{
		ColorInput,player
	},
	props: {
		info: Object,
	},
	data() {
		return {
			initData: {},
			contentMaxHeight:0,
			upCoverModuleList:null,
			upVideoModuleList:null,
			upAudioModuleList:null,
			playModal:{
				pageType:1,
				info:{},
				visible:false,
				cancel:()=>{
					this.playModal.info={};
					this.playModal.visible=false;
				},
				open:(pageType,info,cover)=>{
					if(cover)info.cover = cover
					this.playModal.pageType=pageType;
					this.playModal.info=info;
					this.playModal.visible=true;
				}
			},
			module_301: {
				editIndex: -1,
				editVisible:false,
				radius:10,
				coverRadius:4,
				coverHeight:0,
				radiusVisible1:false,
				radiusVisible2:false,
				heightVisible:false,
				ellipsisBool:true,
				defultData:JSON.stringify({
					title:"王牌飞行员沙溢空战入侵",
					author:"恰饭看电影",
					title_color:'#333333',
					author_color:'#999999',
					radius:4,
					upload_hanlde:{
						progress:101,
					},
					exchanging:{
						progress:0,
						exchanging:false,
						time:0,
						timeleft:0
					},
					video:{
						code: 200,
						cover_img: "",
						duration: 14.28,
						extra_data_defult: {
							upload_type: 'video'
						},
						fileId: 13484,
						file_code: 1,
						file_icon_img: "",
						file_openid: "1bsn44obuce800",
						file_status: 2,
						filename: "935875009dd7096e18b818b8cb9a16f9qt.mp4",
						filesize: "4.79MB",
						filesize_byte: 5017616,
						height: "1080",
						high_task_id: "z2.01z201cshgq2p09sqs00mu7iqu0008kx",
						id: 0,
						key: "video/2023/05/31/AAHMKGOL_BFMH_4HGP_IMEA_LHOOJFFGHCMO.mp4",
						low_task_id: "z2.01z211cshgq2m03zvy00mu7iqu000cpu",
						low_transcode_url: "//view-nc.yasuoxia.com/mbm_test/2023/05/31/70e8d884c5578bbc071fc05be7f696c5.H_25_240.mp4",
						media_status: "1",
						media_type: "video/mp4",
						media_types_a: "video",
						media_types_b: "mp4",
						name: "935875009dd7096e18b818b8cb9a16f9qt.mp4",
						old_transcode_url: "//view-nc.mbm02.cn/video/2023/05/31/AAHMKGOL_BFMH_4HGP_IMEA_LHOOJFFGHCMO.mp4",
						residue_available: 5,
						residue_media_transcode_time: 0,
						t_media_type: "video/mp4",
						total_media_transcode_time: 14,
						transcode_url: "//view-nc.yasuoxia.com/video/2023/05/31/4c022f79ea49007a781276ddfce78f2a.H_25_1.mp4",
						type: "video",
						url: "//view-nc.mbm02.cn/video/2023/05/09/AAHMKGOL_BFMH_4HGP_IMEA_LHOOJFFGHCMO.mp4.mp4",
						width: "1920",
					},
					cover:{
						duration: 0,
						extra_data_defult:{
							upload_type: "img"
						},
						file_icon_img: "/img/defult/file_img@2x.png",
						filename: "0b0836ec9f6ba58bda884b062f8ac459bcddff50.png",
						filesize: "33.99KB",
						filesize_byte: 34807,
						height: 89,
						id: 4158155,
						key: "mbm_test/2023/05/31/bda27e60-6a23-477c-b768-e0338dd3f7ad.png",
						media_type: "image/jpeg",
						media_types_a:"file",
						media_types_b: "jpg",
						path: "//net-img.popziti.com/mbm_test/2023/05/31/bda27e60-6a23-477c-b768-e0338dd3f7ad.png",
						type: "file",
						url: "//net-img.popziti.com/mbm_test/2023/05/31/bda27e60-6a23-477c-b768-e0338dd3f7ad.png",
						width: 158,
					}
				}),
				init: () => {
					this.initData.data.lists.forEach(item=>{
						item.cover.url = this.$baseFunc.getAliOssAuthKey(item.cover.url)
					})
				},
				onPlay: (index) => { //开始播放 
					this.playModal.open(1,this.initData.data.lists[index].video,this.initData.data.lists[index].cover)
				},
				getHtml: () => {
					return this.$refs.module_301.innerHTML;
				},
				selected: (index) => { // 选中下标
					this.module_301.editVisible=true;
					this.module_301.editIndex = index
				},
				hideCard:()=>{ // 隐藏卡片
					this.initData.data.shadow = !this.initData.data.shadow
				},
				changeRadius:(e,type)=>{ // 圆角改变
					if(type=='cover'){
						this.module_301.coverRadius = e
					}else if (type=='height'){
						this.module_301.coverHeight = e;
					}else{
						this.module_301.radius = e
					}
				},
				getRadius:(type)=>{ // 获取圆角
					if(type=='cover'){
						this.module_301.radiusVisible2 = false;
						this.initData.data.lists[this.module_301.editIndex].radius = this.module_301.coverRadius
						this.initData.data.media_radius = this.module_301.coverRadius
					}else if (type=='height'){
						this.module_301.heightVisible = false;
						this.initData.data.cover_height = this.module_301.coverHeight
					} else{
						this.module_301.radiusVisible1 = false;
						this.initData.data.radius = this.module_301.radius
					}
				},
				addBatch:()=> { // 添加一批
					for (let index = 0; index < 2; index++) {
						let defultData = JSON.parse(this.module_301.defultData);
						defultData.cover.url = this.$baseFunc.getAliOssAuthKey(defultData.cover.url)
						this.initData.data.lists.push(defultData)
					}
				},
				addGrid:()=> { // 添加一格
					let defultData = JSON.parse(this.module_301.defultData);
					defultData.cover.url = this.$baseFunc.getAliOssAuthKey(defultData.cover.url)
					this.initData.data.lists.push(defultData)
				},
				addFlow:(index)=>{ // 添加流程
					let defultData = JSON.parse(this.module_301.defultData);
					defultData.cover.url = this.$baseFunc.getAliOssAuthKey(defultData.cover.url)
					this.initData.data.lists.splice(index+1,0,defultData)
				},
				delFlow: (index) => { 	// 删除
					if(this.initData.data.lists.length==1){
						message.warning('至少保留一个视频')
						return false;
					}
					this.initData.data.lists.splice(index, 1)
				},
				moveLeft:(index)=>{ //向左
					if(this.initData.data.lists.some(item=>item.exchanging.exchanging)){
						message.warning('请等待上传完成后在进行移动')
						return
					}
					if (index%2 == 0)return;
					const newIndex = index - 1;
					if(this.module_301.editIndex==index)this.module_301.editIndex=newIndex
					this.initData.data.lists.splice(newIndex, 0, this.initData.data.lists.splice(index, 1)[0]);
				},
				moveRight:(index)=>{ //向右
					if(this.initData.data.lists.some(item=>item.exchanging.exchanging)){
						message.warning('请等待上传完成后在进行移动')
						return
					}
					if (index%2 != 0)return;
					const newIndex = index + 1;
					if(this.module_301.editIndex==index)this.module_301.editIndex=newIndex
					this.initData.data.lists.splice(newIndex, 0, this.initData.data.lists.splice(index, 1)[0]);
				},
				moveDown:(index)=>{ // 向下
					if(this.initData.data.lists.some(item=>item.exchanging.exchanging)){
						message.warning('请等待上传完成后在进行移动')
						return
					}
					if (index == this.initData.data.lists.length - 1 || index == this.initData.data.lists.length - 2 )return;
					const oldIndex = index
					const newIndex = index + 2;
					if(this.module_301.editIndex==index)this.module_301.editIndex=newIndex
					let temp = this.initData.data.lists[newIndex]
					this.initData.data.lists[newIndex] = this.initData.data.lists[oldIndex]
					this.initData.data.lists[oldIndex] = temp
				},
				moveUp:(index)=> {	// 向上
					if(this.initData.data.lists.some(item=>item.exchanging.exchanging)){
						message.warning('请等待上传完成后在进行移动')
						return
					}
					if (index == 0 || index ==1)return;
					const oldIndex = index
					const newIndex = index - 2;
					if(this.module_301.editIndex==index)this.module_301.editIndex=newIndex
					let temp = this.initData.data.lists[newIndex]
					this.initData.data.lists[newIndex] = this.initData.data.lists[oldIndex]
					this.initData.data.lists[oldIndex] = temp
				},
				upCover:(index)=>{ //上传封面
					this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].dispatchEvent(new MouseEvent('click'));
				},
				addSingleImageChange:(e)=>{//选择单个附件
						let files = e.target.files;
						this.initData.data.lists[this.module_301.editIndex].upload_hanlde.progress=0;
						this.$apiRequest.uploadFileToAliOss(this, files[0], progress => {
							/*进度条*/
							this.initData.data.lists[progress.extra_data_defult.index].upload_hanlde.progress=parseInt(progress.percent.toFixed(0));
						}, complete => {}, {
							"upload_type": 'img',
							"index":this.module_301.editIndex,
						}).then(res => {
							this.initData.data.lists[res.extra_data_defult.index]['upload_hanlde']={
								progress:101
							};
							res.url = this.$baseFunc.getAliOssAuthKey(res.url)
							this.initData.data.lists[res.extra_data_defult.index].cover = res
						}).catch(err => {
						});
						this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].value = null
				},
				upVideo:(index)=>{ //上传视频
					this.$refs[`addSingleVideoRef${this.initData.data.tpl_id}`][0].dispatchEvent(new MouseEvent('click'));
				},
				addSingleVideoChange:(e)=>{//选择单个附件
						let files = e.target.files;
						let index = this.module_301.editIndex
						this.initData.data.lists[index].upload_hanlde.progress = 0;
						this.$apiRequest.uploadMediaToQiniu(this,files[0],progress=>{
							/*进度条*/
							this.initData.data.lists[progress.extra_data_defult.index].upload_hanlde.progress=parseInt(progress.percent.toFixed(0));
						},complete=>{
							/*
								complete.code= 1：上传完成 201：正在转
								complete.total_media_transcode_time (预估转码总时间)
								complete.residue_media_transcode_time (预估剩余转码总时间)
							*/
							// console.log("完成：",this.listData.lists)
							if(complete.code==201){
								/*执行转码等待动画，关闭上传进度动画*/
								this.initData.data.lists[complete.extra_data_defult.index]['exchanging']={
									progress:(complete.total_media_transcode_time-complete.residue_media_transcode_time)/complete.total_media_transcode_time*100,
									exchanging:true,
									time:complete.total_media_transcode_time,
									timeleft:complete.residue_media_transcode_time
								}
							}
						},{
							"upload_type":"video",/*video：只能上传视频 ,audio：只能上传音频, media：音视频都可以上传（默认）*/
							"index":this.module_301.editIndex
						}).then(res => {
							this.initData.data.lists[res.extra_data_defult.index]['exchanging'] = {
								progress:101,
								exchanging:false,
								time:0
							}
							this.initData.data.lists[res.extra_data_defult.index]['upload_hanlde'] = {
								progress:101
							}
							this.initData.data.lists[res.extra_data_defult.index].video=res;
						}).catch(err => {
						});
						this.$refs[`addSingleVideoRef${this.initData.data.tpl_id}`][0].value = null
				},
				getText:(e,index,type)=>{ // 获取文本内容
					if(type=='title'){
						this.initData.data.lists[index].title = e.target.innerText
						return
					}
					this.initData.data.lists[index].author = e.target.innerText
				},
				titleFocus:(index)=>{ //标题获取焦点
					this.module_301.ellipsisBool = false
					this.module_301.editIndex = index
					this.$nextTick(()=>{
						this.$refs.hch_video_title_inp[this.module_301.editIndex].focus()
					})
				}
			},
			module_302: {
				editIndex: -1,
				editVisible:false,
				radius:10,
				radiusVisible:false,
				defultData:JSON.stringify({ title: "请输入", text: '请输入', title_color: '#F48665', text_color: '#666666', line_color: '#D7D7D7', align: 'left',change_color:'#ffd8cc' }),
				// 初始化
				init: () => {
				},
				// 获取html
				getHtml: () => {
					return this.$refs.flow.innerHTML;
				},
				// 切换下标
				selected: (index) => {
					this.module_302.editVisible=true;
					this.module_302.editIndex = index
				},
				// 编辑计算高度
				editHeight: (e, index,type) => {
					if(type=='title'){
						const editable = this.$refs.flow_right_title[index];
						const text = editable.innerText;
						if(text.length>30){
							editable.innerText = text.substring(0, 30);
							editable.blur();
							message.warning('标题最大长度为30');
							return
						}
					}
				},
				// 删除
				delFlow: (index) => {
					this.initData.data.lists.splice(index, 1)
				},
				// 修改方向
				direction: (index) => {
					this.initData.data.lists[index].align = this.initData.data.lists[index].align == 'left' ? 'right' : 'left'
				},
				// 隐藏卡片
				hideCard:()=>{
					this.initData.data.shadow = !this.initData.data.shadow
				},
				// 向下
				moveDown:(index)=>{
					if (index === this.initData.data.lists.length - 1)return;
					const newIndex = index + 1;
					if(this.module_302.editIndex==index)this.module_302.editIndex=newIndex
					this.initData.data.lists.splice(newIndex, 0, this.initData.data.lists.splice(index, 1)[0]);
				},
				// 向上
				moveUp:(index)=> {
					if (index === 0)return;
					const newIndex = index - 1;
					if(this.module_302.editIndex==index)this.module_302.editIndex=newIndex;
					this.initData.data.lists.splice(newIndex, 0, this.initData.data.lists.splice(index, 1)[0]);
				},
				// 获取文本内容
				getText:(e,index,type)=>{
					if(type=='title'){
						this.initData.data.lists[index].title = e.target.innerText
						return
					}
					this.initData.data.lists[index].text = e.target.innerText
				},
				// 铺满
				pave:()=>{
					this.initData.data.paveBool = !this.initData.data.paveBool
				},
				// 圆角改变
				changeRadius:(e)=>{
					this.module_302.radius = e
				},
				// 获取圆角
				getRadius:()=>{
					this.module_302.radiusVisible = false;
					this.initData.data.radius = this.module_302.radius
				},
				// 获取圆点颜色
				changeRoundColor:(index)=>{
					this.initData.data.lists[index].change_color = this.$baseFunc.colorDarkSet(this.initData.data.lists[index].title_color);
				},
				// 添加流程
				addFlow:(index)=>{
					this.initData.data.lists.splice(index+1,0,JSON.parse(this.module_302.defultData))
				},
				// 添加一批
				addBatch:()=> {
					for (let index = 0; index < 3; index++) {
						this.initData.data.lists.push(JSON.parse(this.module_302.defultData))
					}
				},
				// 添加一格
				addGrid:()=> {
					this.initData.data.lists.push(JSON.parse(this.module_302.defultData))
				}
			},
			module_303: {
				editVisible:false,
				audioMusic:null,
				schedule:0,
				radius:10,
				audioWidth:0,
				imageMask:0,
				radiusVisible:false,
				widthVisible:false,
				underlayVisible:false,
				changeColor:'',
				audioIndex: 0, //播放音频下标
				audioUrl: "", //音频路径
				audioId: 0, //音频id
				playBool: false, //是否播放 true:正在播放 false:暂停
				totalDuration: "00:00", //音频总时长
				currentTime: "00:00", //当前时长
				init: () => {
					this.module_303.totalDuration = this.$baseFunc.converNumberToTime(this.initData.data.audio.duration)
					this.initData.data.change_color = this.$baseFunc.colorDarkSet(this.initData.data.control_color);
				},
				getHtml: () => {
					return this.$refs.module_303.innerHTML;
				},
				selected: (index) => { // 选中下标
					this.module_303.editVisible=true;
					this.module_303.editIndex = index
				},
				hideImg:()=>{ // 隐藏底图
					this.initData.data.imgBool = !this.initData.data.imgBool
				},
				changeRadius:(e,type)=>{ // 圆角改变
					if(type=='radius'){
						this.module_303.radius = e
					}else if (type=='width'){
						this.module_303.audioWidth = e;
					}else{
						this.module_303.imageMask = e
					}
				},
				getRadius:(type)=>{ // 获取圆角
					if(type=='radius'){
						this.module_303.radiusVisible = false;
						this.initData.data.radius = this.module_303.radius
					}else if (type=='width'){
						this.module_303.widthVisible = false;
						this.initData.data.audio_width = this.module_303.audioWidth
					} else{
						this.module_303.underlayVisible = false;
						this.initData.data.image_mask = this.module_303.imageMask
					}
				},
				getText:(e,type)=>{ // 获取文本内容
					if(type=='title'){
						this.initData.data.title = e.target.innerText
					}else{
						this.initData.data.name = e.target.innerText
					}
				},
				editHeight: (e,type) => { // 编辑计算高度
					if(type=='title'){
						const editable = this.$refs.hch_audio_title;
						const text = editable.innerText;
						if(text.length>21){
							editable.innerText = text.substring(0, 21);
							editable.blur();
							message.warning('标题名称最大长度为21');
							return
						}
					}else{
						const editable = this.$refs.hch_audio_name;
						const text = editable.innerText;
						if(text.length>8){
							editable.innerText = text.substring(0, 8);
							editable.blur();
							message.warning('作者名称最大长度为8');
							return
						}
					}
				},
				upCover:()=>{ //上传封面
					this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].dispatchEvent(new MouseEvent('click'));
				},
				addSingleImageChange:(e)=>{//选择单个附件
						let files = e.target.files;
						this.initData.data.upload_hanlde.progress = 0
						this.$apiRequest.uploadFileToAliOss(this, files[0], progress => {
							/*进度条*/
							this.initData.data.upload_hanlde.progress=parseInt(progress.percent.toFixed(0));
						}, complete => {}, {
							"upload_type": 'img',
						}).then(res => {
							this.initData.data['upload_hanlde']={
								progress:101
							};
							this.initData.data.cover = res
						}).catch(err => {
							message.error(err.message)
						});
						this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].value = null
				},
				upAudio:()=>{ //上传音频
					this.$refs[`addSingleAudioRef${this.initData.data.tpl_id}`][0].dispatchEvent(new MouseEvent('click'));
				},
				addSingleAudioChange:(e)=>{//选择单个附件
						let files = e.target.files;
						this.initData.data.upload_hanlde.progress = 0
						this.$apiRequest.uploadMediaToQiniu(this,files[0],progress=>{
							/*进度条*/
							this.initData.data.upload_hanlde.progress=parseInt(progress.percent.toFixed(0));
						},complete=>{
							if(complete.code==201){
								/*执行转码等待动画，关闭上传进度动画*/
								this.initData.data['exchanging']={
									progress:(complete.total_media_transcode_time-complete.residue_media_transcode_time)/complete.total_media_transcode_time*100,
									exchanging:true,
									time:complete.total_media_transcode_time,
									timeleft:complete.residue_media_transcode_time
								}
							}
						},{
							"upload_type":"audio",/*video：只能上传视频 ,audio：只能上传音频, media：音视频都可以上传（默认）*/
						}).then(res => {
							this.initData.data['exchanging'] = {
								progress:101,
								exchanging:false,
								time:0
							}
							this.initData.data['upload_hanlde'] = {
								progress:101
							}
							this.initData.data.audio=res;
							this.module_303.totalDuration = this.$baseFunc.converNumberToTime(this.initData.data.audio.duration)
						}).catch(err => {
							message.error(err.message)
						});
						this.$refs[`addSingleAudioRef${this.initData.data.tpl_id}`][0].value = null
				},
				onPlay: () => { //开始播放 
					this.playModal.open(2,this.initData.data.audio,null)
				},
				changeRoundColor:()=>{ //计算相邻颜色
					this.initData.data.changeColor = this.$baseFunc.colorDarkSet(this.initData.data.control_color);
				}
			},
			module_304: {
				radius:10,
				radiusVisible:false,
				init: () => {
				},
				changeLink:()=>{
					const that = this
					//换链接
					new linkFuncPackage({
						linkType:'moudle_new',
						link:JSON.parse(JSON.stringify(this.initData.data.link)),
						extraData:{},
						affirm:function (link, extraData) {
							/*确认时回调*/
							that.initData.data.link = link;
						},hide:function (link, extraData) {
							/*关闭时回调*/
						}
					});
				},
				getHtml: () => {
					return this.$refs.module_304.innerHTML;
				},
				changeRadius:(e)=>{ // 圆角改变
					this.module_304.radius =  e
				},
				getRadius:(type)=>{ // 获取圆角
					this.module_304.radiusVisible = false;
					this.initData.data.radius = this.module_304.radius
				},
				getText:(e,type)=>{ // 获取文本内容
					if(type=='title'){
						this.initData.data.title = e.target.innerText
					}else{
						this.initData.data.describe = e.target.innerText
					}
				},
				upCover:()=>{ //上传封面
					this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].dispatchEvent(new MouseEvent('click'));
				},
				addSingleImageChange:(e)=>{//选择单个附件
						let files = e.target.files;
						this.initData.data.upload_hanlde.progress = 0
						this.$apiRequest.uploadFileToAliOss(this, files[0], progress => {
							/*进度条*/
							this.initData.data.upload_hanlde.progress=parseInt(progress.percent.toFixed(0));
						}, complete => {}, {
							"upload_type": 'img',
						}).then(res => {
							this.initData.data['upload_hanlde']={
								progress:101
							};
							this.initData.data.cover = res
						}).catch(err => {
							message.error(err.message)
						});
						this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].value = null
				},
			},
			module_305: {
				editIndex: 0,
				setBool:false,
				projectList:[{
					  "way":1,
					  "title": "微信",
					  "title_color": "#333333",
					  "filterBool": false,
					  "link": {
						"content": ""
					  },
					  "cover": {
						"type": "file",
						"media_type": "image/png",
						"media_types_a": "file",
						"media_types_b": "png",
						"url": "//net-img.popziti.com/mbm_test/2023/05/25/7c996124-0cb6-4e3f-a03c-04eada9f81c0.png",
						"path": "//net-img.popziti.com/mbm_test/2023/05/25/7c996124-0cb6-4e3f-a03c-04eada9f81c0.png",
						"width": "0",
						"height": "0",
						"key": "mbm_test/2023/05/25/7c996124-0cb6-4e3f-a03c-04eada9f81c0.png",
						"filename": "wx.png",
						"filesize": "737B",
						"filesize_byte": 737,
						"id": 4158890,
						"extra_data_defult": {
						  "upload_type": "img"
						},
						"_token": "wglptLroisiXPvAfWShIaJJZm0aGblyIC0kGKhII",
						"duration": 0,
						"file_icon_img": "/img/defult/file_img@2x.png"
					  },
					  "upload_hanlde": {
						"progress": 101
					  },
					  "schedule": 0,
					  "currentTime": "00:00"
					},
					{
					  "way":2,
					  "title": "QQ",
					  "title_color": "#333333",
					  "filterBool": false,
					  "link": {
						"content": ""
					  },
					  "cover": {
						"type": "file",
						"media_type": "image/png",
						"media_types_a": "file",
						"media_types_b": "png",
						"url": "//net-img.popziti.com/mbm_test/2023/05/25/35007171-a299-4f6c-a705-e36b50ca0071.png",
						"path": "//net-img.popziti.com/mbm_test/2023/05/25/35007171-a299-4f6c-a705-e36b50ca0071.png",
						"width": "0",
						"height": "0",
						"key": "mbm_test/2023/05/25/35007171-a299-4f6c-a705-e36b50ca0071.png",
						"filename": "qq.png",
						"filesize": "541B",
						"filesize_byte": 541,
						"id": 4158891,
						"extra_data_defult": {
						  "upload_type": "img"
						},
						"_token": "wglptLroisiXPvAfWShIaJJZm0aGblyIC0kGKhII",
						"duration": 0,
						"file_icon_img": "/img/defult/file_img@2x.png"
					  },
					  "upload_hanlde": {
						"progress": 101
					  }
					},
					{
					  "way":3,
					  "title": "手机",
					  "title_color": "#333333",
					  "filterBool": false,
					  "link": {
						"content": ""
					  },
					  "cover": {
						"type": "file",
						"media_type": "image/png",
						"media_types_a": "file",
						"media_types_b": "png",
						"url": "//net-img.popziti.com/mbm_test/2023/05/25/f597533e-0d77-4082-82e2-f614bce9e786.png",
						"path": "//net-img.popziti.com/mbm_test/2023/05/25/f597533e-0d77-4082-82e2-f614bce9e786.png",
						"width": "0",
						"height": "0",
						"key": "mbm_test/2023/05/25/f597533e-0d77-4082-82e2-f614bce9e786.png",
						"filename": "dh.png",
						"filesize": "594B",
						"filesize_byte": 594,
						"id": 4158892,
						"extra_data_defult": {
						  "upload_type": "img"
						},
						"_token": "wglptLroisiXPvAfWShIaJJZm0aGblyIC0kGKhII",
						"duration": 0,
						"file_icon_img": "/img/defult/file_img@2x.png"
					  },
					  "upload_hanlde": {
						"progress": 101
					  }
					},
					{
					  "way":4,
					  "title": "地址",
					  "title_color": "#333333",
					  "filterBool": false,
					  "link": {
						"content": ""
					  },
					  "cover": {
						"type": "file",
						"media_type": "image/png",
						"media_types_a": "file",
						"media_types_b": "png",
						"url": "//net-img.popziti.com/mbm_test/2023/05/25/9243d35d-816e-441c-aa9a-50a99381ee3b.png",
						"path": "//net-img.popziti.com/mbm_test/2023/05/25/9243d35d-816e-441c-aa9a-50a99381ee3b.png",
						"width": "0",
						"height": "0",
						"key": "mbm_test/2023/05/25/9243d35d-816e-441c-aa9a-50a99381ee3b.png",
						"filename": "dw.png",
						"filesize": "628B",
						"filesize_byte": 628,
						"id": 4158893,
						"extra_data_defult": {
						  "upload_type": "img"
						},
						"_token": "wglptLroisiXPvAfWShIaJJZm0aGblyIC0kGKhII",
						"duration": 0,
						"file_icon_img": "/img/defult/file_img@2x.png"
					  },
					  "upload_hanlde": {
						"progress": 101
					  }
					},
					{
					  "way":5,
					  "title": "座机",
					  "title_color": "#333333",
					  "filterBool": false,
					  "link": {
						"content": ""
					  },
					  "cover": {
						"type": "file",
						"media_type": "image/png",
						"media_types_a": "file",
						"media_types_b": "png",
						"url": "//net-img.popziti.com/mbm_test/2023/05/25/90a3a015-e03d-42ac-ab3f-2b63dfb3d55e.png",
						"path": "//net-img.popziti.com/mbm_test/2023/05/25/90a3a015-e03d-42ac-ab3f-2b63dfb3d55e.png",
						"width": "0",
						"height": "0",
						"key": "mbm_test/2023/05/25/90a3a015-e03d-42ac-ab3f-2b63dfb3d55e.png",
						"filename": "zj.png",
						"filesize": "749B",
						"filesize_byte": 749,
						"id": 4158894,
						"extra_data_defult": {
						  "upload_type": "img"
						},
						"_token": "wglptLroisiXPvAfWShIaJJZm0aGblyIC0kGKhII",
						"duration": 0,
						"file_icon_img": "/img/defult/file_img@2x.png"
					  },
					  "upload_hanlde": {
						"progress": 101
					  }
					},
					{
					  "way":6,
					  "title": "邮箱",
					  "title_color": "#333333",
					  "filterBool": false,
					  "link": {
						"content": ""
					  },
					  "cover": {
						"type": "file",
						"media_type": "image/png",
						"media_types_a": "file",
						"media_types_b": "png",
						"url": "//net-img.popziti.com/mbm_test/2023/05/25/cb7f1b5a-5d8f-4573-87d0-61460bd08ff7.png",
						"path": "//net-img.popziti.com/mbm_test/2023/05/25/cb7f1b5a-5d8f-4573-87d0-61460bd08ff7.png",
						"width": "0",
						"height": "0",
						"key": "mbm_test/2023/05/25/cb7f1b5a-5d8f-4573-87d0-61460bd08ff7.png",
						"filename": "yx.png",
						"filesize": "451B",
						"filesize_byte": 451,
						"id": 4158895,
						"extra_data_defult": {
						  "upload_type": "img"
						},
						"_token": "wglptLroisiXPvAfWShIaJJZm0aGblyIC0kGKhII",
						"duration": 0,
						"file_icon_img": "/img/defult/file_img@2x.png"
					  },
					  "upload_hanlde": {
						"progress": 101
					  }
					},{
					  "way":7,
					  "title": "新浪",
					  "title_color": "#333333",
					  "filterBool": false,
					  "link": {
						"content": ""
					  },
					  "cover": {
							"duration": 0,
							"extra_data_defult": {
								'upload_type': 'img'
							},
							"file_icon_img": "/img/defult/file_img@2x.png",
							"filename": "xl.png",
							"filesize": "821B",
							"filesize_byte": 821,
							"height": "0",
							"id": 4158896,
							"key": "mbm_test/2023/05/25/410a75c7-d8e8-4c40-bdc0-551896c00e4d.png",
							"media_type": "image/png",
							"media_types_a": "file",
							"media_types_b": "png",
							"path": "//net-img.popziti.com/mbm_test/2023/05/25/410a75c7-d8e8-4c40-bdc0-551896c00e4d.png",
							"type": "file",
							"url": "//net-img.popziti.com/mbm_test/2023/05/25/410a75c7-d8e8-4c40-bdc0-551896c00e4d.png",
							"width": "0",
							"_token":"wglptLroisiXPvAfWShIaJJZm0aGblyIC0kGKhII",
					  },
					  "upload_hanlde": {
						"progress": 101
					  }
					}],
				init: () => {

				},
				getHtml: () => {
					return this.$refs.module_305.innerHTML;
				},
				selected: (index) => { // 选中下标
					this.module_305.editIndex = index
					this.module_305.setBool = false
				},
				addFlow:(item,index)=>{ // 添加流程
					this.initData.data.lists.splice(index+1,0,item)
				},
				delFlow: (index) => { 	// 删除
					this.initData.data.lists.splice(index, 1)
				},
				moveLeft:(index)=>{ //向左
					if(this.initData.data.lists.some(item=>item.exchanging.exchanging)){
						message.warning('请等待上传完成后在进行移动')
						return
					}
					if (index === 0)return;
					const newIndex = index - 1;
					if(this.module_305.editIndex==index)this.module_305.editIndex=newIndex;
					this.initData.data.lists.splice(newIndex, 0, this.initData.data.lists.splice(index, 1)[0]);
				},
				moveRight:(index)=>{ //向右
					if(this.initData.data.lists.some(item=>item.exchanging.exchanging)){
						message.warning('请等待上传完成后在进行移动')
						return
					}
					if (index === this.initData.data.lists.length - 1)return;
					const newIndex = index + 1;
					if(this.module_305.editIndex==index)this.module_305.editIndex=newIndex
					this.initData.data.lists.splice(newIndex, 0, this.initData.data.lists.splice(index, 1)[0]);
				},
				upCover:(index)=>{ //上传封面
					this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].dispatchEvent(new MouseEvent('click'));
				},
				addSingleImageChange:(e)=>{//选择单个附件
						let files = e.target.files;
						let index = this.module_305.editIndex
						this.$apiRequest.uploadFileToAliOss(this, files[0], progress => {
							/*进度条*/
							this.initData.data.lists[index].upload_hanlde.progress=parseInt(progress.percent.toFixed(0));
						}, complete => {}, {
							"upload_type": 'img',
						}).then(res => {
							this.initData.data.lists[index]['upload_hanlde']={
								progress:101
							};
							this.initData.data.lists[index].cover = res
						}).catch(err => {
							message.error(err.message)
						});
						this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].value = null
				},
				getText:(e,index,type)=>{ // 获取文本内容
					if(e.target.innerText.length==0){
						e.target.innerText = this.initData.data.lists[index].title
						message.warning('标题不能为空');
					}else{
						this.initData.data.lists[index].title = e.target.innerText
					}
				},
				editHeight: (e, index,type) => { // 编辑计算高度
					const editable = this.$refs.hch_icon_title[index];
					const text = editable.innerText;
					if(text.length>8){
						editable.innerText = text.substring(0, 8);
						editable.blur();
						message.warning('标题最大长度为8');
						return
					}
				},
				titleFocus:(index)=>{ //标题获取焦点
					this.module_305.ellipsisBool = false
					this.module_305.editIndex = index
					this.$nextTick(()=>{
						this.$refs.hch_video_title_inp[this.module_301.editIndex].focus()
					})
				},
				iconColorChange:(index)=>{ //图标变色
					this.initData.data.lists[index].filterBool = !this.initData.data.lists[index].filterBool
				},
				inpChange:(e)=>{ //输入框改变
					if(this.module_305.editIndex==2||this.module_305.editIndex==4){
						const phoneNumberRegex = /^1[3456789]\d{9}$/;
						if(!phoneNumberRegex.test(e.srcElement.value)){
							message.warning("请输入11位数的正确手机号格式")
						}
					}
				}
			},
			module_306: {
				editIndex:-1,
				editVisible:false,
				radius:10,
				radiusVisible:false,
				init: () => {
				},
				getHtml: () => {
					return this.$refs.module_306.innerHTML;
				},
				selected: (index,e) => { // 选中下标
					this.module_306.editVisible=true;
					this.module_306.editIndex = index
				},
				changeLink:(index)=>{
					const that = this
					//换链接
					new linkFuncPackage({
						linkType:'moudle_new',
						link:JSON.parse(JSON.stringify(this.initData.data.lists[index])),
						extraData:{index:index},
						affirm:function (link, extraData) {
							/*确认时回调*/
							that.initData.data.lists[extraData.index].link = link
						},hide:function (link, extraData) {
							/*关闭时回调*/
						}
					});
				},
				changeRadius:(e)=>{ // 圆角改变
					this.module_306.radius =  e
				},
				getRadius:(index)=>{ // 获取圆角
					this.module_306.radiusVisible = false;
					this.initData.data.media_radius = this.module_306.radius
					this.initData.data.lists[index].radius = this.module_306.radius
				},
				getText:(e,type,index)=>{ // 获取文本内容
					this.initData.data.lists[index].title = e.target.innerText
				},
				upCover:()=>{ //上传封面
					this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].dispatchEvent(new MouseEvent('click'));
				},
				addSingleImageChange:(e)=>{//选择单个附件
					let files = e.target.files;
					this.initData.data.lists[this.module_306.editIndex].upload_hanlde.progress= 0
					this.$apiRequest.uploadFileToAliOss(this, files[0], progress => {
						/*进度条*/
						this.initData.data.lists[progress.extra_data_defult.index].upload_hanlde.progress=parseInt(progress.percent.toFixed(0));
					}, complete => {}, {
						"upload_type": 'img',
						"index":this.module_306.editIndex,
					}).then(res => {
						this.initData.data.lists[res.extra_data_defult.index]['upload_hanlde']={progress:101};
						this.initData.data.lists[res.extra_data_defult.index].cover = res
					}).catch(err => {
					});
					this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].value = null
				},
			},
			module_307: {
				editIndex:-1,
				editVisible:false,
				radius:10,
				radiusVisible:false,
				init: () => {
				},
				changeLink:(index)=>{
					const that = this
					//换链接
					new linkFuncPackage({
						linkType:'moudle_new',
						link:JSON.parse(JSON.stringify(this.initData.data.lists[index])),
						extraData:{index:index},
						affirm:function (link, extraData) {
							/*确认时回调*/
							that.initData.data.lists[extraData.index].link = link
						},hide:function (link, extraData) {
							/*关闭时回调*/
						}
					});
				},
				getHtml: () => {
					return this.$refs.module_307.innerHTML;
				},
				selected: (index,e) => { // 选中下标
					this.module_307.editVisible=true;
					this.module_307.editIndex = index
				},
				changeRadius:(e)=>{ // 圆角改变
					this.module_307.radius =  e
				},
				getRadius:(index)=>{ // 获取圆角
					this.module_307.radiusVisible = false;
					this.initData.data.media_radius = this.module_307.radius
					this.initData.data.lists[index].radius = this.module_307.radius
				},
				getText:(e,type,index)=>{ // 获取文本内容
					this.initData.data.lists[index].title = e.target.innerText
				},
				upCover:()=>{ //上传封面
					this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].dispatchEvent(new MouseEvent('click'));
				},
				addSingleImageChange:(e)=>{//选择单个附件
						let files = e.target.files;
						this.initData.data.lists[this.module_307.editIndex].upload_hanlde.progress=0
						this.$apiRequest.uploadFileToAliOss(this, files[0], progress => {
							/*进度条*/
							this.initData.data.lists[progress.extra_data_defult.index].upload_hanlde.progress=parseInt(progress.percent.toFixed(0));
						}, complete => {}, {
							"upload_type": 'img',
							"index":this.module_307.editIndex,
						}).then(res => {
							this.initData.data.lists[res.extra_data_defult.index]['upload_hanlde']={progress:101};
							this.initData.data.lists[res.extra_data_defult.index].cover = res
						}).catch(err => {
						});
						this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].value = null
				},
			},
			module_308: {
				editIndex:-1,
				editVisible:false,
				radius:10,
				height:0,
				ellipsisBool:true,
				popoverShow:false,
				radiusVisible:false,
				heightVisible:false,
				defultData:JSON.stringify({
					radius:10,
					title:'请输入',
					title_color:'#ffffff',
					upload_hanlde:{
						progress:101,
					},
					cover:{},
					link:null
				}),
				init: () => {
				},
				getHtml: () => {
					return this.$refs.module_308.innerHTML;
				},
				selected: (index,e) => { // 选中下标
					this.module_308.editVisible=true;
					this.module_308.editIndex = index
				},
				changeRadius:(e,type)=>{ // 圆角改变
					if(type=='height'){
						this.module_308.height =  e
					}else{
						this.module_308.radius =  e
					}
				},
				getRadius:(index,type)=>{ // 获取圆角
					if(type=='height'){
						this.initData.data.imgHeight = this.module_308.height
					}else{
						this.initData.data.media_radius = this.module_308.radius
						this.initData.data.lists[index].radius = this.module_308.radius
					}
					this.module_308.radiusVisible = false;
					this.module_308.heightVisible = false;
				},
				getText:(e,type)=>{ // 获取文本内容
					if(type=='title'){
						this.initData.data.title = e.target.innerText
					}else if (type=='iTitle') {
						this.initData.data.lists[this.module_308.editIndex].title = e.target.innerText
					}else{
						this.initData.data.introduce = e.target.innerText
					}
				},
				upCover:()=>{ //上传封面
					this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].dispatchEvent(new MouseEvent('click'));
				},
				addSingleImageChange:(e)=>{//选择单个附件
						let files = e.target.files;
						this.initData.data.lists[this.module_308.editIndex].upload_hanlde.progress=0
						this.$apiRequest.uploadFileToAliOss(this, files[0], progress => {
							/*进度条*/
							this.initData.data.lists[progress.extra_data_defult.index].upload_hanlde.progress=parseInt(progress.percent.toFixed(0));
						}, complete => {}, {
							"upload_type": 'img',
							"index":this.module_308.editIndex,
						}).then(res => {
							this.initData.data.lists[res.extra_data_defult.index]['upload_hanlde']={
								progress:101
							};
							this.initData.data.lists[res.extra_data_defult.index].cover = res
						}).catch(err => {
							message.error(err.message)
						});
						this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].value = null
				},
				changeTitle:()=>{ //改变标题
					this.initData.data.titleBool = !this.initData.data.titleBool
				},
				moveLeft:(index)=>{ //向左
					if(this.initData.data.lists.some(item=>item.exchanging.exchanging)){
						message.warning('请等待上传完成后在进行移动')
						return
					}
					if (index === 0)return;
					const newIndex = index - 1;
					if(this.module_308.editIndex==index)this.module_308.editIndex=newIndex;
					this.initData.data.lists.splice(newIndex, 0, this.initData.data.lists.splice(index, 1)[0]);
				},
				moveRight:(index)=>{ //向右
					if(this.initData.data.lists.some(item=>item.exchanging.exchanging)){
						message.warning('请等待上传完成后在进行移动')
						return
					}
					if (index === this.initData.data.lists.length - 1)return;
					const newIndex = index + 1;
					if(this.module_308.editIndex==index)this.module_308.editIndex=newIndex
					this.initData.data.lists.splice(newIndex, 0, this.initData.data.lists.splice(index, 1)[0]);
				},
				delFlow: (index) => { 	// 删除
					this.initData.data.lists.splice(index, 1)
				},
				addFlow:(index)=>{ //添加
					this.initData.data.lists.splice(index+1,0,JSON.parse(this.module_308.defultData))
				},
				addBatch:()=> { //添加一批
					for (let index = 0; index < 3; index++) {
						this.initData.data.lists.push(JSON.parse(this.module_308.defultData))
					}
				},
				addGrid:()=> { //添加一格
					this.initData.data.lists.push(JSON.parse(this.module_308.defultData))
				},
				titleFocus:(index)=>{ //标题获取焦点
					this.module_308.ellipsisBool = false
					this.module_308.editIndex = index
					this.$nextTick(()=>{
						this.$refs.hch_img_text_link_box_title_inp[this.module_308.editIndex].focus()
					})
				}
			},
			module_309: {
				editIndex: -1,
				editVisible:false,
				radius:10,
				coverRadius:4,
				coverHeight:0,
				radiusVisible1:false,
				radiusVisible2:false,
				ellipsisBool:true,
				defultData:JSON.stringify({
					title:"巨鳄",
					author:'1925年，一条巨鳄因意外逃出牢笼，在北',
					title_color:'#333333',
					author_color:'#999999',
					radius:4,
					upload_hanlde:{
						progress:101,
					},
					exchanging:{
						progress:0,
						exchanging:false,
						time:0,
						timeleft:0
					},
					video:{
						code: 200,
						cover_img: "",
						duration: "34",
						extra_data_defult: {
							upload_type: 'video'
						},
						fileId: 4158160,
						file_code: 1,
						file_icon_img: "",
						file_openid: "1bsn44obuce800",
						file_status: 2,
						filename: "935875009dd7096e18b818b8cb9a16f9qt.mp4",
						filesize: "14.42MB",
						filesize_byte: 15118342,
						height: 1080,
						high_task_id: "z2.01z201cshgq2p09sqs00mu7iqu0008kx",
						id: 4158160,
						key: "video/2023/05/09/BIPAEGAC_AIJC_4AGJ_INMI_DMOFBPGFLILG.mp4",
						low_task_id: "z2.01z211cshgq2m03zvy00mu7iqu000cpu",
						low_transcode_url: "//view-nc.yasuoxia.com/mbm_test/2023/05/09/4302c30adc8ab9bbb396aa832d1c2ba5.H_25_240.mp4",
						media_status: "1",
						media_type: "video/mp4",
						media_types_a: "video",
						media_types_b: "mp4",
						name: "935875009dd7096e18b818b8cb9a16f9qt.mp4",
						old_transcode_url: "//view-nc.mbm02.cn/video/2023/05/09/BIPAEGAC_AIJC_4AGJ_INMI_DMOFBPGFLILG.mp4",
						residue_available: 5,
						residue_media_transcode_time: 0,
						t_media_type: "video/mp4",
						total_media_transcode_time: 14,
						transcode_url: "//view-nc.yasuoxia.com/video/2023/05/09/88038dbd257ac350ea67f18b8a8a6d25.H_25_1.mp4",
						type: "video",
						url: "//view-nc.mbm02.cn/video/2023/05/09/BIPAEGAC_AIJC_4AGJ_INMI_DMOFBPGFLILG.mp4",
						width: 1920,
						_token: "wglptLroisiXPvAfWShIaJJZm0aGblyIC0kGKhII"
					},
					cover:{
						duration: 0,
						extra_data_defult:{
							upload_type: "img"
						},
						file_icon_img: "/img/defult/file_img@2x.png",
						filename: "0b0836ec9f6ba58bda884b062f8ac459bcddff50.png",
						filesize: "17.43KB",
						filesize_byte: 17848,
						height: 70,
						id: 0,
						key: "mbm_test/2023/05/31/03f9cef2-3643-425e-918c-0c047c3093de.png",
						media_type: "image/jpeg",
						media_types_a:"file",
						media_types_b: "png",
						path: "//net-img.popziti.com/mbm_test/2023/05/31/03f9cef2-3643-425e-918c-0c047c3093de.png",
						type: "file",
						url: "//net-img.popziti.com/mbm_test/2023/05/31/03f9cef2-3643-425e-918c-0c047c3093de.png",
						width: 100,
					}
				}),
				init: () => {

				},
				onPlay: (index) => { //开始播放
					this.playModal.open(1,this.initData.data.lists[index].video,this.initData.data.lists[index].cover)
				},
				getHtml: () => {
					return this.$refs.module_309.innerHTML;
				},
				selected: (index,e) => { // 选中下标
					this.module_309.editVisible=true;
					this.module_309.editIndex = index
				},
				hideCard:()=>{ // 隐藏卡片
					this.initData.data.shadow = !this.initData.data.shadow
				},
				changeRadius:(e,type)=>{ // 圆角改变
					if(type=='cover'){
						this.module_309.coverRadius = e
					}else if (type=='height'){
						this.module_309.coverHeight = e;
					}else{
						this.module_309.radius = e
					}
				},
				getRadius:(type)=>{ // 获取圆角
					if(type=='cover'){
						this.module_309.radiusVisible2 = false;
						this.initData.data.lists[this.module_309.editIndex].radius = this.module_309.coverRadius
						this.initData.data.media_radius = this.module_309.coverRadius
					}else if (type=='height'){
						this.module_309.heightVisible = false;
						this.initData.data.cover_height = this.module_309.coverHeight
					} else{
						this.module_309.radiusVisible1 = false;
						this.initData.data.radius = this.module_309.radius
					}
				},
				addFlow:(index)=>{ // 添加流程
					this.initData.data.lists.splice(index+1,0,JSON.parse(this.module_309.defultData))
				},
				delFlow: (index) => { 	// 删除
					if(this.initData.data.lists.length==1){
						message.warning('至少保留一个')
						return false;
					}
					this.initData.data.lists.splice(index, 1)
				},
				moveDown:(index)=>{ // 向下
					if(this.initData.data.lists.some(item=>item.exchanging.exchanging)){
						message.warning('请等待上传完成后在进行移动')
						return
					}
					if (index === this.initData.data.lists.length - 1)return;
					const newIndex = index + 1;
					if(this.module_309.editIndex==index)this.module_309.editIndex=newIndex
					this.initData.data.lists.splice(newIndex, 0, this.initData.data.lists.splice(index, 1)[0]);
				},
				moveUp:(index)=> {	// 向上
					if(this.initData.data.lists.some(item=>item.exchanging.exchanging)){
						message.warning('请等待上传完成后在进行移动')
						return
					}
					const newIndex = index - 1;
					if(this.module_309.editIndex==index)this.module_309.editIndex=newIndex;
					this.initData.data.lists.splice(newIndex, 0, this.initData.data.lists.splice(index, 1)[0]);
				},
				upCover:(index)=>{ //上传封面
					this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].dispatchEvent(new MouseEvent('click'));
				},
				addSingleImageChange:(e)=>{//选择单个附件
					let files = e.target.files;
					let index = this.module_309.editIndex
					this.initData.data.lists[index].upload_hanlde.progress = 0
					this.$apiRequest.uploadFileToAliOss(this, files[0], progress => {
						/*进度条*/
						this.initData.data.lists[progress.extra_data_defult.index].upload_hanlde.progress=parseInt(progress.percent.toFixed(0));
					}, complete => {}, {
						"upload_type": 'img',
						"index":index
					}).then(res => {
						this.initData.data.lists[res.extra_data_defult.index]['upload_hanlde']={
							progress:101
						};
						this.initData.data.lists[res.extra_data_defult.index].cover = res
					}).catch(err => {
					});
					this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].value = null
				},
				upVideo:(index)=>{ //上传视频
					this.$refs[`addSingleVideoRef${this.initData.data.tpl_id}`][0].dispatchEvent(new MouseEvent('click'));
				},
				addSingleVideoChange:(e)=>{//选择单个附件
						let files = e.target.files;
						let index = this.module_309.editIndex
						this.initData.data.lists[index].upload_hanlde.progress= 0
						this.$apiRequest.uploadMediaToQiniu(this,files[0],progress=>{
							/*进度条*/
							this.initData.data.lists[progress.extra_data_defult.index].upload_hanlde.progress=parseInt(progress.percent.toFixed(0));
						},complete=>{
							/*
								complete.code= 1：上传完成 201：正在转
								complete.total_media_transcode_time (预估转码总时间)
								complete.residue_media_transcode_time (预估剩余转码总时间)
							*/
							// console.log("完成：",this.listData.lists)
							if(complete.code==201){
								/*执行转码等待动画，关闭上传进度动画*/
								this.initData.data.lists[complete.extra_data_defult.index]['exchanging']={
									progress:(complete.total_media_transcode_time-complete.residue_media_transcode_time)/complete.total_media_transcode_time*100,
									exchanging:true,
									time:complete.total_media_transcode_time,
									timeleft:complete.residue_media_transcode_time
								}
							}
						},{
							"upload_type":"video",/*video：只能上传视频 ,audio：只能上传音频, media：音视频都可以上传（默认）*/
							"index":index,
						}).then(res => {
							this.initData.data.lists[res.extra_data_defult.index]['exchanging'] = {
								progress:101,
								exchanging:false,
								time:0
							}
							this.initData.data.lists[res.extra_data_defult.index]['upload_hanlde'] = {
								progress:101
							}
							this.initData.data.lists[res.extra_data_defult.index].video=res;
						}).catch(err => {
						});
						this.$refs[`addSingleVideoRef${this.initData.data.tpl_id}`][0].value = null
				},
				addBatch:()=> { // 添加一批
					for (let index = 0; index < 2; index++) {
						this.initData.data.lists.push(JSON.parse(this.module_309.defultData))
					}
				},
				addGrid:()=> { // 添加一格
					this.initData.data.lists.push(JSON.parse(this.module_309.defultData))
				},
				getText:(e,index,type)=>{ // 获取文本内容
					if(type=='title'){
						this.initData.data.lists[index].title = e.target.innerText
						return
					}
					this.initData.data.lists[index].author = e.target.innerText
				},
				editHeight: (e, index,type) => { // 编辑计算高度
					if(type=='title'){
						const editable = this.$refs.hch_video_list_title[index];
						const text = editable.innerText;
						if(text.length>21){
							editable.innerText = text.substring(0, 12);
							editable.blur();
							message.warning('标题名称最大长度为12');
							return
						}
					}
				},
				titleFocus:(index)=>{ //标题获取焦点
					this.module_309.ellipsisBool = false
					this.module_309.editIndex = index
					this.$nextTick(()=>{
						this.$refs.hch_video_list_author_inp[this.module_309.editIndex].focus()
					})
				}
			},
			module_310: {
				audioMusic:null,
				editIndex: -1,
				editVisible:false,
				radius:10,
				coverRadius:4,
				coverHeight:0,
				radiusVisible1:false,
				radiusVisible2:false,
				ellipsisBool:true,
				audioIndex: -1, //播放音频下标
				audioUrl: "", //音频路径
				audioId: 0, //音频id
				playBool: false, //是否播放 true:正在播放 false:暂停
				defultData:JSON.stringify({
					title:"旅行音曲.mp3",
					author:'作者',
					title_color:'#333333',
					author_color:'#999999',
					radius:4,
					upload_hanlde:{
						progress:101,
					},
					exchanging:{
						progress:0,
						exchanging:false,
						time:0,
						timeleft:0
					},
					audio:{
						code: 200,
						cover_img: "",
						duration: 10.03,
						extra_data_defult: {
							upload_type: 'audio'
						},
						fileId: 4158160,
						file_code: 1,
						file_icon_img: "",
						file_openid: "1bsn44obuce800",
						file_status: 2,
						filename: "c20.mp3",
						filesize: "157.08KB",
						filesize_byte: 160852,
						height: 0,
						high_task_id: "z2.01z201cshgq2p09sqs00mu7iqu0008kx",
						id: 0,
						key: "audio/2023/06/02/HJHLNJPG_DJEE_4FAM_IELB_GABMMBDCHNNF.mp3",
						low_task_id: "z2.01z211cshgq2m03zvy00mu7iqu000cpu",
						low_transcode_url: "//view-nc.yasuoxia.com/mbm_test/2023/06/02/e6e12f44977df9d40ae655ce039db082.H_25_240.mp3",
						media_status: "1",
						media_type: "audio/mp3",
						media_types_a: "audio",
						media_types_b: "mp4",
						name: "935875009dd7096e18b818b8cb9a16f9qt.mp4",
						old_transcode_url: "//view-nc.mbm02.cn/audio/2023/06/02/HJHLNJPG_DJEE_4FAM_IELB_GABMMBDCHNNF.mp3",
						residue_available: 5,
						residue_media_transcode_time: 0,
						t_media_type: "audio/mp3",
						total_media_transcode_time: 14,
						transcode_url: "//view-nc.yasuoxia.com/audio/2023/06/02/c394a6e90bf06f46f6e328471d7a6ff0.H_25_1.mp3",
						type: "audio",
						url: "//view-nc.mbm02.cn/audio/2023/06/02/HJHLNJPG_DJEE_4FAM_IELB_GABMMBDCHNNF.mp3",
						width: 0,
						_token: "wglptLroisiXPvAfWShIaJJZm0aGblyIC0kGKhII"
					},
					cover:{
						duration: 0,
						extra_data_defult:{
							upload_type: "img"
						},
						file_icon_img: "/img/defult/file_img@2x.png",
						filename: "d512ee12-a165-42d9-ab2c-68c910af64c8.png",
						filesize: "17.43KB",
						filesize_byte: 17848,
						height: 100,
						id: 4158155,
						key: "mbm_test/2023/05/31/d512ee12-a165-42d9-ab2c-68c910af64c8.png",
						media_type: "image/jpeg",
						media_types_a:"file",
						media_types_b: "jpg",
						path: "//net-img.popziti.com/mbm_test/2023/05/31/d512ee12-a165-42d9-ab2c-68c910af64c8.png",
						type: "file",
						url: "//net-img.popziti.com/mbm_test/2023/05/31/d512ee12-a165-42d9-ab2c-68c910af64c8.png",
						width: 100,
					}
				}),
				init: () => {

				},
				getHtml: () => {
					return this.$refs.module_310.innerHTML;
				},
				selected: (index,e) => { // 选中下标
					this.module_310.editVisible=true;
					this.module_310.editIndex = index
				},
				hideCard:()=>{ // 隐藏卡片
					this.initData.data.shadow = !this.initData.data.shadow
				},
				changeRadius:(e,type)=>{ // 圆角改变
					if(type=='cover'){
						this.module_310.coverRadius = e
					}else if (type=='height'){
						this.module_310.coverHeight = e;
					}else{
						this.module_310.radius = e
					}
				},
				getRadius:(type)=>{ // 获取圆角
					if(type=='cover'){
						this.module_310.radiusVisible2 = false;
						this.initData.data.lists[this.module_310.editIndex].radius = this.module_310.coverRadius
						this.initData.data.media_radius = this.module_310.coverRadius
					}else if (type=='height'){
						this.module_310.heightVisible = false;
						this.initData.data.audioHeight = this.module_310.coverHeight
					} else{
						this.module_310.radiusVisible1 = false;
						this.initData.data.radius = this.module_310.radius
					}
				},
				addFlow:(index)=>{ // 添加流程
					this.initData.data.lists.splice(index+1,0,JSON.parse(this.module_310.defultData))
				},
				delFlow: (index) => { 	// 删除
					this.initData.data.lists.splice(index, 1)
				},
				moveDown:(index)=>{ // 向下
					if(this.initData.data.lists.some(item=>item.exchanging.exchanging)){
						message.warning('请等待上传完成后在进行移动')
						return
					}
					if (index === this.initData.data.lists.length - 1)return;
					const newIndex = index + 1;
					if(this.module_310.editIndex==index)this.module_310.editIndex=newIndex
					this.initData.data.lists.splice(newIndex, 0, this.initData.data.lists.splice(index, 1)[0]);
				},
				moveUp:(index)=> {	// 向上
					if(this.initData.data.lists.some(item=>item.exchanging.exchanging)){
						message.warning('请等待上传完成后在进行移动')
						return
					}
					const newIndex = index - 1;
					if(this.module_310.editIndex==index)this.module_310.editIndex=newIndex;
					this.initData.data.lists.splice(newIndex, 0, this.initData.data.lists.splice(index, 1)[0]);
				},
				upCover:(index)=>{ //上传封面
					this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].dispatchEvent(new MouseEvent('click'));
				},
				addSingleImageChange:(e)=>{//选择单个附件
					let files = e.target.files;
					let index = this.module_310.editIndex
					this.$apiRequest.uploadFileToAliOss(this, files[0], progress => {
						/*进度条*/
						this.initData.data.lists[progress.extra_data_defult.index].upload_hanlde.progress=parseInt(progress.percent.toFixed(0));
					}, complete => {}, {
						"upload_type": 'img',
						"index":index,
					}).then(res => {
						this.initData.data.lists[res.extra_data_defult.index]['upload_hanlde']={
							progress:101
						};
						this.initData.data.lists[res.extra_data_defult.index].cover = res
					}).catch(err => {
						message.error(err.message)
					});
					this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].value = null
				},
				upAudio:()=>{ //上传音频
					this.$refs[`addSingleAudioRef${this.initData.data.tpl_id}`][0].dispatchEvent(new MouseEvent('click'));
				},
				addSingleAudioChange:(e)=>{//选择单个附件
						let files = e.target.files;
						let index = this.module_310.editIndex
						this.initData.data.lists[index].upload_hanlde.progress = 0
						this.$apiRequest.uploadMediaToQiniu(this,files[0],progress=>{
							/*进度条*/
							this.initData.data.lists[progress.extra_data_defult.index].upload_hanlde.progress=parseInt(progress.percent.toFixed(0));
						},complete=>{
							if(complete.code==201){
								/*执行转码等待动画，关闭上传进度动画*/
								this.initData.data.lists[complete.extra_data_defult.index]['exchanging']={
									progress:(complete.total_media_transcode_time-complete.residue_media_transcode_time)/complete.total_media_transcode_time*100,
									exchanging:true,
									time:complete.total_media_transcode_time,
									timeleft:complete.residue_media_transcode_time
								}
							}
						},{
							"upload_type":"audio",/*video：只能上传视频 ,audio：只能上传音频, media：音视频都可以上传（默认）*/
							"index":index
						}).then(res => {
							this.initData.data.lists[res.extra_data_defult.index]['exchanging'] = {
								progress:101,
								exchanging:false,
								time:0
							}
							this.initData.data.lists[res.extra_data_defult.index]['upload_hanlde'] = {
								progress:101
							}
							this.initData.data.lists[res.extra_data_defult.index].audio=res;
						}).catch(err => {
							message.error(err.message)
						});
						this.$refs[`addSingleAudioRef${this.initData.data.tpl_id}`][0].value = null
				},
				addBatch:()=> { // 添加一批
					for (let index = 0; index < 2; index++) {
						this.initData.data.lists.push(JSON.parse(this.module_310.defultData))
					}
				},
				addGrid:()=> { // 添加一格
					this.initData.data.lists.push(JSON.parse(this.module_310.defultData))
				},
				getText:(e,index,type)=>{ // 获取文本内容
					if(index==-1){
						this.initData.data.title = e.target.innerText
					}else{
						this.initData.data.lists[index].title = e.target.innerText
					}
				},
				editHeight: (e, index,type) => { // 编辑计算高度
					if(type=='title'){
						const editable = this.$refs.hch_video_list_title[index];
						const text = editable.innerText;
						if(text.length>21){
							editable.innerText = text.substring(0, 12);
							editable.blur();
							message.warning('标题名称最大长度为12');
							return
						}
					}
				},
				titleFocus:(index)=>{ //标题获取焦点
					this.module_310.ellipsisBool = false
					this.module_310.editIndex = index
					this.$nextTick(()=>{
						this.$refs.hch_video_list_author_inp[this.module_310.editIndex].focus()
					})
				},
				onPlay: (index) => { //开始播放 
					this.playModal.open(2,this.initData.data.lists[index].audio,null)
				},
			},
			module_311: {
				audioMusic:null,
				editIndex: 0,
				radius:10,
				coverRadius:4,
				coverHeight:0,
				radiusVisible:false,
				ellipsisBool:true,
				audioIndex: -1, //播放音频下标
				audioUrl: "", //音频路径
				audioId: 0, //音频id
				playBool: false, //是否播放 true:正在播放 false:暂停
				defultData:JSON.stringify({
					title:"标题",
					author:'作者',
					title_color:'#333333',
					author_color:'#999999',
					schedule_color:'#10D379',
					radius:4,
					audioUrl:'https://aod.cos.tx.xmcdn.com/storages/c6bd-audiofreehighqps/E9/EF/GKwRIRwHYaaxAAgVQwHbQcn9.m4a',
					totalDuration: "00:00", //音频总时长
					currentTime: "00:00", //当前时长
					upload_hanlde:{
						progress:101,
					},
					exchanging:{
						progress:0,
						exchanging:false,
						time:0,
						timeleft:0
					},
					audio:{
							code: 200,
							cover_img: "",
							duration: "34",
							extra_data_defult: {
								upload_type: 'video'
							},
							fileId: 4158160,
							file_code: 1,
							file_icon_img: "",
							file_openid: "1bsn44obuce800",
							file_status: 2,
							filename: "935875009dd7096e18b818b8cb9a16f9qt.mp4",
							filesize: "14.42MB",
							filesize_byte: 15118342,
							height: "1080",
							high_task_id: "z2.01z201cshgq2p09sqs00mu7iqu0008kx",
							id: 4158160,
							key: "video/2023/05/09/BIPAEGAC_AIJC_4AGJ_INMI_DMOFBPGFLILG.mp4",
							low_task_id: "z2.01z211cshgq2m03zvy00mu7iqu000cpu",
							low_transcode_url: "//view-nc.yasuoxia.com/mbm_test/2023/05/09/4302c30adc8ab9bbb396aa832d1c2ba5.H_25_240.mp4",
							media_status: "1",
							media_type: "video/mp4",
							media_types_a: "video",
							media_types_b: "mp4",
							name: "935875009dd7096e18b818b8cb9a16f9qt.mp4",
							old_transcode_url: "//view-nc.mbm02.cn/video/2023/05/09/BIPAEGAC_AIJC_4AGJ_INMI_DMOFBPGFLILG.mp4",
							residue_available: 5,
							residue_media_transcode_time: 0,
							t_media_type: "video/mp4",
							total_media_transcode_time: 14,
							transcode_url: "//view-nc.yasuoxia.com/video/2023/05/09/88038dbd257ac350ea67f18b8a8a6d25.H_25_1.mp4",
							type: "video",
							url: "//view-nc.mbm02.cn/video/2023/05/09/BIPAEGAC_AIJC_4AGJ_INMI_DMOFBPGFLILG.mp4",
							width: "1920",
							_token: "wglptLroisiXPvAfWShIaJJZm0aGblyIC0kGKhII"
						},
					cover:{
							duration: 0,
							extra_data_defult:{
								upload_type: "img"
							},
							file_icon_img: "/img/defult/file_img@2x.png",
							filename: "0b0836ec9f6ba58bda884b062f8ac459bcddff50.jpg",
							filesize: "96.08KB",
							filesize_byte: 98383,
							height: "0",
							id: 4158155,
							key: "mbm_test/2023/05/09/f4a30f6d-f978-4aaa-969c-5cf8d418daa7.jpg",
							media_type: "image/jpeg",
							media_types_a:"file",
							media_types_b: "jpg",
							path: "//net-img.popziti.com/mbm_test/2023/05/09/f4a30f6d-f978-4aaa-969c-5cf8d418daa7.jpg",
							type: "file",
							url: "//net-img.popziti.com/mbm_test/2023/05/09/f4a30f6d-f978-4aaa-969c-5cf8d418daa7.jpg",
							width: "0",
							_token:"wglptLroisiXPvAfWShIaJJZm0aGblyIC0kGKhII",
						}
				}),
				init: () => {

				},
				getHtml: () => {
					return this.$refs.module_311.innerHTML;
				},
				selected: (index) => { // 选中下标
					this.module_311.radiusVisible = false
					if(this.module_311.editIndex!=index&&this.module_311.playBool){
						this.initData.data.lists[this.module_311.editIndex].schedule = 0
						this.initData.data.lists[this.module_311.editIndex].currentTime = "00:00"
						this.module_311.audioMusic.currentTime = 0
						this.module_311.editIndex = index
						this.module_311.audioMusic.pause()
						this.module_311.audioMusic.playBool = false
					}else{
						this.module_311.editIndex = index
					}
				},
				hideCard:()=>{ // 隐藏卡片
					this.initData.data.shadow = !this.initData.data.shadow
				},
				changeRadius:(e,type)=>{ // 圆角改变
					if(type=='cover'){
						this.module_311.coverRadius = e
					}else if (type=='height'){
						this.module_311.coverHeight = e;
					}else{
						this.module_311.radius = e
					}
				},
				getRadius:(type)=>{ // 获取圆角
					if(type=='cover'){
						this.module_311.radiusVisible2 = false;
						this.initData.data.lists[this.module_311.editIndex].radius = this.module_311.coverRadius
					}else if (type=='height'){
						this.module_311.heightVisible = false;
						this.initData.data.audioHeight = this.module_311.coverHeight
					} else{
						this.module_311.radiusVisible1 = false;
						this.initData.data.radius = this.module_311.radius
					}
				},
				addFlow:(index)=>{ // 添加流程
					this.initData.data.lists.splice(index+1,0,JSON.parse(this.module_311.defultData))
				},
				delFlow: (index) => { 	// 删除
					this.initData.data.lists.splice(index, 1)
				},
				moveDown:(index)=>{ // 向下
					if(this.initData.data.lists.some(item=>item.exchanging.exchanging)){
						message.warning('请等待上传完成后在进行移动')
						return
					}
					if (index === this.initData.data.lists.length - 1)return;
					const newIndex = index + 1;
					if(this.module_311.editIndex==index)this.module_311.editIndex=newIndex
					this.initData.data.lists.splice(newIndex, 0, this.initData.data.lists.splice(index, 1)[0]);
				},
				moveUp:(index)=> {	// 向上
					if(this.initData.data.lists.some(item=>item.exchanging.exchanging)){
						message.warning('请等待上传完成后在进行移动')
						return
					}
					const newIndex = index - 1;
					if(this.module_311.editIndex==index)this.module_311.editIndex=newIndex;
					this.initData.data.lists.splice(newIndex, 0, this.initData.data.lists.splice(index, 1)[0]);
				},
				upCover:(index)=>{ //上传封面
					this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].dispatchEvent(new MouseEvent('click'));
				},
				addSingleImageChange:(e)=>{//选择单个附件
					let files = e.target.files;
					let index = this.module_311.editIndex
					this.$apiRequest.uploadFileToAliOss(this, files[0], progress => {
						/*进度条*/
						this.initData.data.lists[index].upload_hanlde.progress=parseInt(progress.percent.toFixed(0));
					}, complete => {}, {
						"upload_type": 'img',
					}).then(res => {
						this.initData.data.lists[index]['upload_hanlde']={
							progress:101
						};
						this.initData.data.lists[index].cover = res
					}).catch(err => {
						message.error(err.message)
					});
					this.$refs[`addSingleImageRef${this.initData.data.tpl_id}`][0].value = null
				},
				upAudio:()=>{ //上传音频
					this.$refs[`addSingleAudioRef${this.initData.data.tpl_id}`][0].dispatchEvent(new MouseEvent('click'));
				},
				addSingleAudioChange:(e)=>{//选择单个附件
						let files = e.target.files;
						let idnex = this.module_311.editIndex
						this.$apiRequest.uploadMediaToQiniu(this,files[0],progress=>{
							/*进度条*/
							this.initData.data.lists[idnex].upload_hanlde.progress=parseInt(progress.percent.toFixed(0));
						},complete=>{
							if(complete.code==201){
								/*执行转码等待动画，关闭上传进度动画*/
								this.initData.data.lists[idnex]['exchanging']={
									progress:(complete.total_media_transcode_time-complete.residue_media_transcode_time)/complete.total_media_transcode_time*100,
									exchanging:true,
									time:complete.total_media_transcode_time,
									timeleft:complete.residue_media_transcode_time
								}
							}
						},{
							"upload_type":"audio",/*video：只能上传视频 ,audio：只能上传音频, media：音视频都可以上传（默认）*/
						}).then(res => {
							this.initData.data.lists[idnex]['exchanging'] = {
								progress:101,
								exchanging:false,
								time:0
							}
							this.initData.data.lists[idnex]['upload_hanlde'] = {
								progress:101
							}
							this.initData.data.lists[idnex].audio=res;
						}).catch(err => {
							message.error(err.message)
						});
						this.$refs[`addSingleAudioRef${this.initData.data.tpl_id}`][0].value = null
				},
				addBatch:()=> { // 添加一批
					for (let index = 0; index < 2; index++) {
						this.initData.data.lists.push(JSON.parse(this.module_311.defultData))
					}
				},
				addGrid:()=> { // 添加一格
					this.initData.data.lists.push(JSON.parse(this.module_311.defultData))
				},
				getText:(e,index,type)=>{ // 获取文本内容
					this.initData.data.lists[index].title = e.target.innerText
				},
				editHeight: (e, index,type) => { // 编辑计算高度
					if(type=='title'){
						const editable = this.$refs.hch_video_list_title[index];
						const text = editable.innerText;
						if(text.length>21){
							editable.innerText = text.substring(0, 12);
							editable.blur();
							message.warning('标题名称最大长度为12');
							return
						}
					}
				},
				titleFocus:(index)=>{ //标题获取焦点
					this.module_311.ellipsisBool = false
					this.module_311.editIndex = index
					this.$nextTick(()=>{
						this.$refs.hch_video_list_author_inp[this.module_311.editIndex].focus()
					})
				},
				canplay: () => { //准备就绪
				},
				playing: () => { //播放中
					this.module_311.playBool = !this.module_311.audioMusic.paused;
				},
				waiting: () => { //缓冲中
				},
				timeupdate: () => { //播放进度监听
					if (this.module_311.playBool) {
						this.initData.data.lists[this.module_311.editIndex].currentTime = this.$baseFunc.converNumberToTime(this.module_311.audioMusic.currentTime)
						this.initData.data.lists[this.module_311.editIndex].schedule = this.module_311.audioMusic.currentTime / this.module_311.audioMusic.duration * 100
					}
				},
				seeking: () => { //跳转

				},
				seeked: () => { //跳转结束

				},
				pause: () => { //暂停
					this.module_311.playBool = !this.module_311.audioMusic.paused;
				},
				ended: () => { //播放结束
					this.module_311.playBool = !this.module_311.audioMusic.paused;
				},
				error: () => { //播放错误
					this.initData.data.lists[this.module_311.editIndex].schedule = 0
					this.initData.data.lists[this.module_311.editIndex].currentTime = "00:00"
				},
				onPlay: (index) => { //开始播放 
					if(index==this.module_311.audioIndex){
						this.module_311.playBool = true
						this.module_311.audioMusic.play()
					}else{
						this.module_311.playBool = true
						this.module_311.audioIndex = index
						// this.module_311.audioUrl = this.$baseFunc.getQiniusign(this.initData.data.audio.url)
						this.module_311.audioUrl = this.initData.data.lists[index].audioUrl
						this.$nextTick(()=>this.module_311.audioMusic.play())
					}
				},
				onPause: (index) => { //暂停播放
					this.module_311.playBool = false
					if(this.module_311.audioIndex==index&&!this.module_311.playBool){
						this.module_311.audioMusic.pause()
					}else{
						this.module_311.onPlay(index)
					}
				},
				onAfterChange:(value)=> { //进度条改变
					this.module_311.audioMusic.currentTime = this.initData.data.lists[this.module_311.editIndex].audio.duration * (value / 100);
					this.module_311.audioIndex = this.module_311.editIndex
					this.module_311.onPlay(this.module_311.editIndex)
				},
				onAfterStop:()=> { //进度条改变时停止播放
					this.module_311.onPause()
				},
			},
		}
	},
	mounted() {
		this.module_303.audioMusic = this.$refs.musicRef
		this.module_310.audioMusic = this.$refs.musicRef_310
		this.module_311.audioMusic = this.$refs.musicRef_311
	},
	created() {
		this.upCoverModuleList = [
			{ id: 301, addSingleImageChange:this.module_301.addSingleImageChange },
			{ id: 303, addSingleImageChange:this.module_303.addSingleImageChange },
			{ id: 304, addSingleImageChange:this.module_304.addSingleImageChange },
			{ id: 305, addSingleImageChange:this.module_305.addSingleImageChange },
			{ id: 306, addSingleImageChange:this.module_306.addSingleImageChange },
			{ id: 307, addSingleImageChange:this.module_307.addSingleImageChange },
			{ id: 308, addSingleImageChange:this.module_308.addSingleImageChange },
			{ id: 309, addSingleImageChange:this.module_309.addSingleImageChange },
			{ id: 310, addSingleImageChange:this.module_310.addSingleImageChange },
			{ id: 311, addSingleImageChange:this.module_311.addSingleImageChange },
		]
		this.upVideoModuleList = [
		    { id: 301, addSingleVideoChange:this.module_301.addSingleVideoChange },
		    { id: 309, addSingleVideoChange:this.module_309.addSingleVideoChange },
		]
		this.upAudioModuleList = [
		    { id: 303, addSingleAudioChange:this.module_303.addSingleAudioChange },
		    { id: 310, addSingleAudioChange:this.module_310.addSingleAudioChange },
			{ id: 311, addSingleAudioChange:this.module_311.addSingleAudioChange },
		]
		this.init();
	},
	methods: {
		init() {/*初始化数据*/
			this.contentMaxHeight = document.documentElement.clientHeight * 0.7;
			let moudleInitData = JSON.parse(JSON.stringify(this.info));
			if (!moudleInitData.data||(typeof moudleInitData.data.initNullData != 'undefined' && moudleInitData.data.initNullData == 1)) {
				moudleInitData.data = JSON.parse(JSON.stringify(moduleStoreroomDefaultDatah1xh82h7yxt2[`module_${moudleInitData.data.tpl_id}`]));
			}
			this.initData = moudleInitData;
			this[`module_${this.initData.data.tpl_id}`].init();
		},
		getData() {
			let data = JSON.parse(JSON.stringify(this.initData.data));
			return new Promise(function(resolve, reject) {
				let msg = '';
				switch(parseInt(data.tpl_id)){
					case 301:
					case 309:
						data.lists.forEach(item=>{
							if(!msg && item.exchanging.exchanging){
								msg="视频转码中，请稍后……"
							}
							if(!msg && item.upload_hanlde.progress!=101){
								msg="视频上传中，请稍后……"
							}
						})
						break;
					case 303:
						if(!msg && data.exchanging.exchanging){
							msg="音频转码中，请稍后……"
						}
						if(!msg && data.upload_hanlde.progress!=101){
							msg="音频上传中，请稍后……"
						}
						break;
					case 304:
						if(!msg && data.upload_hanlde.progress!=101){
							msg="图片上传中，请稍后……"
						}
						break;
					case 306:
					case 307:
					case 308:
						data.lists.forEach(item=>{
							if(!msg && item.upload_hanlde.progress!=101){
								msg="图片上传中，请稍后……"
							}
						})
						break;
					case 310:
						data.lists.forEach(item=>{
							if(!msg && item.exchanging.exchanging){
								msg="音频转码中，请稍后……"
							}
							if(!msg && item.upload_hanlde.progress!=101){
								msg="音频上传中，请稍后……"
							}
						})
						break;
				}
				if(msg){
					message.warning(msg)
					reject()
				}else{
					resolve({
						data:data,
						html:'',
					})
				}
			})
		},
	},
	computed:{
	},
	watch: {
		info: {
			deep: true,
			handler(newval, oldval) {
				if (newval != oldval) {
					this.init()
				}
			}
		},
	},
}
</script>

<style lang="scss">
	
	.hch_box p{margin: 0;}
	.hch_box *{box-sizing: border-box;}
	.add_module {
		margin: 0;
		text-align: right;
		padding-right: 20px;
		padding-bottom: 20px;
		span {
			cursor: pointer;
			font-size: 14px;
			color: #848684;
		}
	}
	.hch_box_scroll{
		overflow-y: auto;
		max-height: calc(100vh - 300px);
	}
	.hch_flow_warp{
		display: flex;
		justify-content: center;
		padding: 12px 0;
	}
	.hch_flow {
		width: 540px;
		padding: 16px;
		height: 100%;
		.hch_flow_box {
			border: 2px dashed #fff;
			display: flex;
			padding: 13px 14px;
			position: relative;
			.hch_prover {
				width: 100%;
				display: flex;
			}
			.hch_flow_left {
				margin: 0;
				padding-top: 7px;
				position: relative;
				text-align: center;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.hch_flow_left_drop {
					border-radius: 50%;
					width: 12px;
					height: 12px;
					display: flex;
					align-items: center;
					justify-content: center;
					.hch_flow_left_drop-inner{
						border-radius: 50%;
						width: 50%;
						height: 50%;
					}
				}
				.hch_flow_left_line {
					margin-top: 5px;
					width: 1px;
					height: calc(100% - 5px - 12px);
					background: #D7D7D7;
				}
			}

			.hch_flow_right {
				.hch_flow_right_title {
					padding: 0 10px;
					line-height: 26px;
					text-align: center;
					border-radius: 13px;
					font-size: 14px;
					font-weight: bold;
					color: #FFFFFF;
					border: 0;
					display: block;
					
					&:focus {
						outline: none;
					}
				}
				.hch_flow_right_content {
					resize: none;
					border: 0;
					margin-top: 20px;
					font-size: 14px;
					color: #666666;
					line-height: 21px;
					text-align: left;
					word-break: break-all;
					word-wrap: break-word;
					white-space: pre-line;
					&:focus {
						outline: none;
					}
				}
			}

		}

		.hch_flow_box_selected {
			border: 2px dashed #09BD71;
		}
	}
	.hch_video{
		width: 80.1%;
		margin: auto;
		padding: 14px 15px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		box-shadow: 1px 1px 30px #ccc;
		border: 2px dashed #fff;
		.hch_video_box{
		width: calc(50% - 9px);
		margin-top: 16px;
		padding: 9px 0;
		&:nth-child(-n+2){
			margin-top: 0;
		}
		}
		.hch_video_box_selected {
			outline: 2px dashed #09BD71;
		}
		.hch_video_cover_box{
			position: relative;
			width:100%;
			overflow: hidden;
			.hch_video_duration{
				position: absolute;
				left: 14px;
				bottom: 14px;
				font-size: 12px;
				color: #FFFFFF;
				z-index:3;
			}
			img{
				position: absolute;
				z-index:1;
				width: 100%;
				height:100%;
				border-radius: 4px;
				object-fit: cover;
				object-position: center;
			}
			
			.hch_card_audio_play{
				width: 26px;
				height: 26px;
				border-radius: 100%;
				background-color: rgba(0, 0, 0, 0.5);
				position: absolute;
				left: 50%;
				top: 50%;
				z-index:2;
				transform: translate(-50%,-50%);
				i{
					font-size:12px;
					position: absolute;
					left: 50%;
					top: 50%;
					color:#fff;
					transform: translate(-50%,-50%);
				}
			}
		}
		.hch_video_title{
		width: 100%;
		margin-top: 4px;
		font-size: 14px;
		color: #333333;
		overflow: hidden;
   	white-space: nowrap;
		border: 0;
		padding: 0;
   	text-overflow: ellipsis;
		margin-bottom: 0;
		&:focus {
			outline: none;
			// text-overflow: unset;
		}
		}
		.hch_video_author{
		font-size: 12px;
		font-weight: 400;
		color: #999999;
		margin-bottom: 0;
		&:focus {
			outline: none;
		}
		}
	}
	.hch_audio{
		margin: auto;
		.hch_audio_box{
			width: 100%;
			height: 100%;
			.hch_audio_bg{
				width: 100%;
				min-height: 226px;
				position: relative;
				.hch_audio_cont{
					padding: 40px 37px 31px 37px;
					width: 100%;
					height: 100%;
					position: relative;
					z-index: 12;
					.hch_audio_title{
						font-size: 16px;
						font-weight: 550;
						color: #FFFFFF;
						line-height: 1;
						&:focus{
							outline: none;
						}
					}
					.hch_audio_name{
						margin-top: 20px;
						font-size: 14px;
						color: #D3D5E2;
						line-height: 1;
						&:focus{
							outline: none;
						}
					}
					.hch_audio_control{
						.hch_audio_slider_box{
							width: 100%;
    					margin-top: 40px;
    					margin-bottom: 12px;
    					height: 2px;
							background-color: #ccc;
							position: relative;
						}
						.hch_audio_slider_box>div{
							position: absolute;
							left: 0;
							top: 50%;
							transform: translateY(-50%);
							width: 13px;
							height: 13px;
							background-color: #EAEAEA;
							border-radius: 50%;
						}
						.hch_audio_slider{
							margin: 0;
							margin-top: 34px;
							margin-bottom: 7px;
							.ant-slider-rail{
								height: 2px;
							}
							.ant-slider-track{
								height: 2px;
								background-color: var(--curentSlider);
							}
							.ant-slider-handle{
								width: 13px;
								height: 13px;
								background: var(--curentSlider);
								border: none;
								&:focus{
									box-shadow:none;
								}
							}
							.ant-slider-rail{
								background-color: var(--changeColor);
							}
						}
						.hch_audio_time{
							display: flex;
							justify-content: space-between;
							span{
								font-size: 14px;
								color: #FFFFFF;
								line-height: 1;
							}
						}
						.hch_audio_button{
							margin-top: 9px;
							position: relative;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 100%;
							height: 32px;
							cursor: pointer;
							svg:nth-child(1){
								width: 25px;
								height: 32px;
							}
							svg:nth-child(2){
								position: absolute;
								right: 0;
								width: 22px;
								height: 22px;
								border-radius: 1px;
							}
						}
					}
				}
				img{
					width: 100%;
					height: 100%;
					position: absolute;
					object-fit: cover;
				}
			}
		}
	}
	.hch_link{
		width: 80%;
		margin: auto;
		box-shadow:0 2px 10px 0 rgba(0,0,0,.15);
		overflow: hidden;
		.hch_link_box{
			width: 100%;
			.hch_link_content{
				width: 100%;
				.hch_link_cont{
					padding: 24px 33px 23px 33px;
					.hch_link_title_box{
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 7px;
						.hch_link_title{
							font-size: 16px;
							font-weight: 550;
							white-space: pre-line;
							margin-bottom: 0;
							&:focus{
								outline: none;
							}
						}
						span{
							color: #999999;
						}
					}
					.hch_link_describe{
						font-size: 14px;
						color: #999999;
						line-height: 24px;
						white-space: pre-line;
						&:focus{
							outline: none;
						}
					}
				}
				img{
					width: 100%;
				}
			}
		}
	}
	.hch_icon_inp_num{
		.ant-input-number-handler-wrap{
			display: none !important;
		}
	}
	.hch_icon_link{
		width: 80%;
		margin: auto;
		display: flex;
    flex-wrap: wrap;
		.hch_icon_link_box{
			height: 98px;
			padding: 5px;
			.hch_icon_content{
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				.hch_icon_title{
					margin-top: 4px;
					font-size: 14px;
					color: #333333;
					&:focus{
						outline:none
					}
				}
				img{
					height: 26px;
					object-fit: cover;
				}
			}
			&:nth-child(3n + 1){
				width: 33.3%;
				position: relative;
				&::before{
					content: "";
					position: absolute;
					width: 0.5px;
					height: 100%;
					background-color: #e6edef;
					right: 0px;
					bottom: 0%;
				}
			}
			&:nth-child(3n + 2){
				width: 33.3%;
				position: relative;
				&::after{
					content: "";
					position: absolute;
					width: 0.5px;
					height: 100%;
					background-color: #e6edef;
					right: 0px;
					bottom: 0%;
				}
			}
			&:nth-child(3n + 3){
				width: 33.3%;
				position: relative;
			}
		}
		.hch_icon_link_selected{
			outline: 2px dashed #09BD71;
		}
		
	}
	.hch_img_link{
		width:90%;
		.hch_img_link_content{
			width:100%;
			display:flex;
			justify-content: space-between;
			.hch_img_link_left{
				padding: 6px 8px;
				width: 58%;
				box-sizing: border-box;
			}
			.hch_img_link__item{
				position:relative;
				width:100%;
				padding-bottom:92%;
			}
			.hch_img_link_right{
				width: 42%;
				height: auto;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.hch_img_link_right_top{
					padding: 6px 8px;
					position: relative;
					width: 100%;
					box-sizing:border-box;
					.hch_img_link__item{
						padding-bottom:62%;
					}
					.hch_img_link_title{
						left: 22px;
						bottom: 15px;
						color: #fff;
					}
				}
				.hch_img_link_right_bottom{
					// padding: 6px 8px;
					width: 100%;
					display: flex;
					justify-content:space-between;
					.hch_img_link__item{
						padding-bottom:129%;
					}
					.hch_img_link_right_bottom_left,
					.hch_img_link_right_bottom_right{
						overflow:hidden;
						text-overflow:examples;
						// white-space:nowrap;
						position: relative;
						box-sizing:border-box;
						padding: 6px 8px;
						width:50%;
						.hch_img_link_title{
							left: 22px;
							bottom: 15px;
							color: #fff;
						}
					}
				}
			}
			.hch_img_link_title{
				width:70%;
				position: absolute;
				left: 22px;
				bottom: 21px;
				color: #fff;
				&:focus{
					outline:none;
				}
			}
			.hch_img_link_selected{
				outline: 2px dashed #09BD71;
			}
			img{
				position:absolute;
				left:0;
				right:0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.hch_img_text_link{
		width: 85%;
		.hch_img_text_link_title{
			font-size: 18px;
			font-weight: 550;
			color: #333333;
			margin-bottom: 8px !important;
			&:focus{
				outline:none;
			}
		}
		.hch_img_text_link_introduce{
			font-size: 12px;
			font-weight: 300;
			color: #999999;
			&:focus{
				outline:none;
			}
		}
		.hch_img_text_link_box{
			padding:10px 3px;
			width: 100%;
			overflow-y: hidden;
			display: -webkit-box;
			align-items: flex-start;
			justify-content: center;
			overflow-x: auto;
			&::-webkit-scrollbar {
				height:6px;
			}
			&>div{
				&>div{
					overflow:hidden;
					position:relative;
					.hch_img_text_link_box_item{
						position: relative;
						width: 100%;
						padding-bottom: 100%;
						img{
							position: absolute;
							left:0;
							top:0;
							width: 100%;
							height:100%;
							object-fit: cover;
						}
					}
					.hch_img_text_link_box_title{
						position:absolute;
						width:80%;
						bottom:20px;
						left:27px;
						font-size: 16px;
						overflow:hidden;
						white-space: nowrap;
						text-overflow:ellipsis;
						border: 0;
						padding: 0;
						background-color: inherit;
						&:focus{
							outline:none;
						}
					}
				}
			}
			.hch_img_text_link_box_selected{
				outline: 2px dashed #09BD71;
			}
		}
	}
	.hch_video_list{
		width: 85%;
		margin: auto;
		padding: 14px 15px;
		box-shadow: 1px 1px 30px #ccc;
		border: 2px dashed #fff;
		.hch_video_list_box{
			width: 100%;
			// margin-top: 16px;
			padding: 9px;
		}
		.hch_video_list_box_selected {
			outline: 2px dashed #09BD71;
		}
		.hch_video_list_cover_box{
			position: relative;
			display: flex;
			.hch_video_list_info{
				flex: 1;
				margin-left: 18px;
				display: flex;
    		flex-direction: column;
    		justify-content: space-evenly;
				overflow: hidden;
			}
			.hch_video_list_duration{
				font-size: 12px;
				color: #131010;
				line-height: 1;
				span{
					img{
						vertical-align: initial;
					}
				}
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
				position: absolute;
				left:0;
				top:0;
			}
		}
		.hch_video_list_title{
			width: 75%;
			font-size: 14px;
			color: #333333;
			overflow: hidden;
  	 	white-space: nowrap;
			border: 0;
			padding: 0;
  	 	text-overflow: ellipsis;
			line-height: 1;
			&:focus {
				outline: none;
				line-height: 1;
			}
		}
		.hch_video_list_author{
			width: 70%;
			height: 13px;
			font-size: 12px;
			font-weight: 400;
			color: #999999;
			line-height: 1;
			overflow: hidden;
  	 	white-space: nowrap;
			border: 0;
			padding: 0;
  	 	text-overflow: ellipsis;
			&:focus {
				outline: none;
			}
		}
		.hch_card_audio_play{
			width: 26px;
			height: 26px;
			border-radius: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			position: absolute;
			left: 50%;
			top: 50%;
			z-index:2;
			transform: translate(-50%,-50%);
			i{
				font-size:12px;
				position: absolute;
				left: 50%;
				top: 50%;
				color:#fff;
				transform: translate(-50%,-50%);
			}
		}
	}
	.hch_card_audio{
		width: 83%;
		margin: auto;
		position:relative;
		padding: 33px 24px 27px;
		.hch_card_audio_title{
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			line-height: 1;
			margin-bottom: 17px;
			outline: none;
		}
		.hch_card_audio_icon{
			position: absolute;
			left: 0;
			top: 27px;
			width: 4px;
			height: 32px;
			background: #09BD71;
			border-top-right-radius: 50px;
			border-bottom-right-radius: 50px;
		}
		.hch_card_audio_box{
			display:flex;
			justify-content: space-between;
			.hch_card_audio_cover_box{
				padding:7px 8px;
				width: 33.3%;
				.hch_card_audio_content{
					width: 100%;
					// height: 160px;
					.hch_card_audio_play{
						width: 41px;
						height: 41px;
						border-radius: 100%;
						background-color: rgba(0, 0, 0, 0.5);
						position: absolute;
						left: calc(50% + 1px);
						top: 50%;
						transform: translate(-50%,-50%);
						i{
							font-size:19px;
							position: absolute;
							left: 50%;
							top: 50%;
							color:#fff;
							transform: translate(-50%,-50%);
						}
					}
				}
				.hch_card_audio_item_title{
					width:100%;
					margin-top:10px;
					line-height:1;
					&:focus{
						outline:none;
					}
				}
			}
		}
		.hch_card_audio_cover_box_selected{
			outline: 2px dashed #09BD71;
		}
		img{
			width: 100%;
			height: 100%;
			object-fit:cover;
		}
	}
	.hch_audio_list{
		width:80%;
		margin:auto;
		.hch_audio_list_box{
			padding:0px 13px;
			margin: 15px 0;
			.hch_audio_list_content{
				padding:8px 0px;
				width: 100%;
				display: flex;
				justify-content:flex-start;
				position: relative;
				.hch_audio_item_play{
					width: 20%;
					position: relative;
  				padding-top: 20%;
					img{
						position: absolute;
  					top: 0;
  					left: 0;
  					width: 100%;
  					height: 100%;
  					object-fit: cover;
					}
					.hch_audio_list_play{
						width: 26px;
						height: 26px;
						border-radius: 100%;
						background-color: rgba(0, 0, 0, 0.5);
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%,-50%);
						img{
							width:9px;
							height: 12px;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%,-50%);
						}
					}
				}
				.hch_audio_item_text{
					flex:1;
					margin-left:20px;
					display: flex;
					flex-direction: column;
					justify-content: space-evenly;
					.hch_audio_item_title{
						font-size: 14px;
						color: #333333;
						line-height: 1;
						width: 100%;
					}
					.hch_audio_item_author{
						font-size: 12px;
						font-weight: 400;
						color: #BFBFBF;
						line-height: 1;
						// margin-top: 8px;
						// margin-bottom: 28px;
						width: 100%;
					}
					.hch_audio_item_schedule{
						display: flex;
						align-items: center;
						justify-content: space-between;
						.hch_audio_slider{
							margin: 0;
							padding: 0;
							height: 0;
							flex: 1;
							.ant-slider-rail,.ant-slider-track,.ant-slider-step{
								height: 3px;
							}
							.ant-slider-track{
								background: var(--curentSlider);
							}
							.ant-slider-handle{
								width: 0;
								height: 0;
								border: none;
							}
							.ant-slider-handle-click-focused{
								box-shadow: none;
								width: 0;
								height:0;
							}
						}
						span{
							margin-left:20px;
							font-size: 10px;
							font-weight: 400;
							color: #BFBFBF;
						}
					}
				}
				&::before{
					content: "";
					width: 100%;
					height:1px;
					position: absolute;
					top: -8px;
					left: 0;
					background-color: #CCC;
				}
			}
			&:first-child .hch_audio_list_content::before{
				content: '';
				height: 0;
			}
		}
		.hch_audio_list_box_selected{
			outline: 2px dashed #09BD71;
		}
	}

	.mask {
		position: absolute;
		height: 100%;
		// min-height: 226px;
		width: 100%;
		z-index: 8;
	}
	.loadingCon{
		width: 100%;
		height: 100%;
		position: absolute;
		left:0;
		bottom:0;
		background-color: #e0f9ee;
		z-index:20;
		display:flex;
		justify-content: center;
		border-radius:4px;
		align-items: center;
		flex-direction: column;
		padding:0 10px;
	}
	.listContainer .ant-btn:hover{
	color: #09bd71;
	background: #fff;
	border-color: #09bd71;
	}
	.link_title{
		display: flex;
		align-items: center;
		color: #777777;
		img{
			margin-right: 5px;
			width: 15px;
			height: 15px;
		}
	}
	.changeTime{
		width:100%;
		text-align:center;
		position: relative;
		padding:0;
		margin:0;
		padding-top: 4px;
		font-size: 12px;
		color:#09bd71;
	}
	.hch_prover_edit {
		margin: 0;
		display: flex;
		align-items: center;
		.hch_prover_edit_span {
			margin-right:23px;
			color: #4F68BA;
			font-size: 14px;
			display: flex;
			align-items: center;
			cursor: pointer;
			img{
				width: 20px;
				height: 20px;
			}
			&:last-child {
				margin-right:0px;
			}
		}
	}
	.fillet_popover {
	.ant-popover-inner-content {
		padding: 10px 16px;
		margin-top: 5px;
	}
	.ant-popover-arrow{
		width: 0px !important;
		height: 0px !important;
		border: none;
	}
	}
	.color-input .box{
	display: flex;
	align-items: center;
	width: 15px;
	height: 15px;
	border: 2px solid #eaeaea;
	}
	.hch_card_audio_play_j1hus{
		width: 32px;
		height: 32px;
		border-radius: 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		z-index:2;
		transform: translate(-50%,-50%);
		i{
			font-size:32px;
			color:rgba(0,0,0,0.5);
			line-height: 1;
		}
		svg{
			font-size:32px;
		}
	}
</style>
