import { createApp } from 'vue'
import App from './App.vue'
import apiAxios from '../../config/request.js'
import baseFunc from '../../config/base_func.js';
import Antd from 'ant-design-vue';
import baiduMap from 'vue-baidu-map';
import 'ant-design-vue/dist/antd.less';

const app = createApp(App);
app.config.globalProperties.$apiRequest = apiAxios; 
app.config.globalProperties.$baseFunc = baseFunc; 
//将实例挂载至节点

app.use(Antd).mount('#module__storeroom');
// app.use(baiduMap,{ak:"dog4OaEZ5tiUdAPfWLETNZ8iErmYmBan"})
